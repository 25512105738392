import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { Notification } from '../../../store/models/notification';
import { AccommodationRequestStatus } from '../../../utils/AccommodationRequestStatus';
import { StatusEnum } from '../../../store/models/status';

export const AccommodationRequestInProgress = ({ notification }: { notification: Notification }) => {
    const textColor = useColorModeValue('navy.700', 'white');

    return (
        <Flex pr={ 5 }>
            <Flex
                justify='center'
                align='center'
                minH={ { base: "60px", md: "70px" } }
                h={ { base: "60px", md: "70px" } }
                minW={ { base: "60px", md: "70px" } }
                w={ { base: "60px", md: "70px" } }
            >
                { AccommodationRequestStatus[notification.data.status as StatusEnum].icon }
            </Flex>
            <Flex flexDirection='column'>
                <Text
                    fontWeight='bold'
                    color={ textColor }
                    fontSize={ { base: "md", md: "md" } }>
                    Request In Progress
                </Text>
                <Text
                    fontSize={ { base: "sm", md: "sm" } }
                    lineHeight="100%"
                    color={ textColor }
                    fontStyle={'italic'}
                >
                    #{ notification.data.accommodationRequestId }
                </Text>
                <Text
                    fontSize={ { base: "sm", md: "sm" } }
                    color={ textColor }>
                    {notification.data.caseManager.name} moved your request to `In Progress`.
                </Text>
                <Text
                    fontSize={ { base: 'sm', md: 'sm' } }
                    color={ textColor }
                    lineHeight="100%"
                >
                    { notification.created_at }
                </Text>
            </Flex>
        </Flex>
    );
}
