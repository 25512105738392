import { observable, action, makeObservable } from 'mobx'
import { AxiosResponse, AxiosError } from 'axios';
import { createStandaloneToast } from '@chakra-ui/react';
import { request } from 'store/axios';

import { Notification } from 'store/models/notification';

const toast = createStandaloneToast();

enum NotificationEndpoints {
    GET_NOTIFICATIONS = '/api/wpas/user/notifications',
    PUT_MARK_AS_READ = '/api/wpas/user/notifications/mark-as-read',
}

class NotificationStore {
    @observable loading: boolean = false;
    @observable notifications: Notification[];
    @observable unreadCount: number;

    constructor() {
        makeObservable(this);
    }

    @action
    setLoading = (value: boolean = true) => {
        this.loading = value;
    }

    @action
    setUnreadCount = (value: number) => {
        this.unreadCount = value;
    }

    @action
    setNotifications = (notifications: Notification[]) => {
        this.notifications = notifications;
    }

    @action
    addNotification = (notification: Notification) => {
        this.notifications = [notification, ...this.notifications];
    }

    onError = (error: string) => {
        toast({
            title: 'Error occurred',
            description: error,
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            isClosable: true,
        });
    };

    getNotifications = async () => {
        this.setLoading();
        try {
            const response = await request.get<AxiosResponse>(NotificationEndpoints.GET_NOTIFICATIONS);
            const responseData = response.data as any;

            this.setNotifications(responseData.notifications);
            this.setUnreadCount(responseData.unreadCount);
        } catch (error: AxiosError | any) {
            return Promise.reject(error.response);
        } finally { this.setLoading(false); }
    }

    @action
    markNotificationsAsRead = async () => {
        this.setLoading();
        try {
            const response = await request.put<AxiosResponse>(NotificationEndpoints.PUT_MARK_AS_READ);

            const responseData = response.data as any;

            this.setNotifications(responseData.notifications);
            this.setUnreadCount(responseData.unreadCount);
        } catch (err: AxiosError | any) {
        } finally {
            this.setLoading(false);
        }
    }
}

const notificationStore = new NotificationStore();
export default notificationStore;
