import {
  Box,
  Button,
  Flex,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import Card from '../../../../components/card/Card';
import { AccommodationRequestStatus } from '../../../../utils/AccommodationRequestStatus';
import { StatusEnum } from 'store/models/status';
import accommodationRequestStore from 'store/dataStore/accommodationRequestStore';
import LoadingOverlay from 'components/loader/LoadingOverlay';

interface IAccommodationInfoHeaderProps {
  handleOnUpdate: (status?: string) => void;
  headerInfo: {
    status: string;
    caseManager?: any;
    lastUpdated: string;
    accommodationName: string;
    id: string;
  };
}

const AccommodationInfoHeader: React.FC<IAccommodationInfoHeaderProps> = (
  props
) => {
  const { status, caseManager, lastUpdated, accommodationName, id } =
    props.headerInfo;
  const displayStatus =
    AccommodationRequestStatus[status as StatusEnum] || null;
  const [loading, setLoading] = useState(false);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const buttonSize = useBreakpointValue(['sm', 'sm', 'sm', 'md']);

  const handleWithdrawRequest = async () => {
    try {
      setLoading(true);
      await accommodationRequestStore.updateStatus(id, StatusEnum.WITHDRAWN);
      await accommodationRequestStore.getAccommodationRequest(id);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <LoadingOverlay isLoading={loading}>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          gap={3}
          alignItems={'start'}
          justifyContent={'space-between'}
        >
          <div>
            <Text color={textColor} fontSize='lg' fontWeight={'bold'}>
              #{id}
            </Text>
            <Text color='brand.500' fontWeight='bold' fontSize='xl'>
              {accommodationName}
            </Text>
            <Box my={5}>
              <Text color={textColor} fontSize='lg' display={'flex'}>
                Status:&nbsp;&nbsp;{' '}
                <Text as='span' fontWeight={'bold'}>
                  {displayStatus && (
                    <Flex gap={2} alignItems='center'>
                      {displayStatus.title}
                      {displayStatus.icon}
                    </Flex>
                  )}
                </Text>
              </Text>
              <Text color={textColor} fontSize='lg' display={'flex'}>
                Case Manager:&nbsp;&nbsp;
                <Text as='span' fontWeight={'bold'}>
                  {' '}
                  {`${
                    caseManager
                      ? `${caseManager?.firstName} ${caseManager?.lastName}`
                      : 'Not Assigned'
                  }`}{' '}
                </Text>
              </Text>
              <Text color={textColor} fontSize='lg' display={'flex'}>
                {`Last Updated:`}&nbsp;&nbsp;
                <Text as='span' fontWeight={'bold'}>
                  {' '}
                  {`${lastUpdated}`}{' '}
                </Text>
              </Text>
            </Box>
            <Box>
              <Button
                onClick={() => props.handleOnUpdate()}
                size={buttonSize}
                colorScheme={'navy'}
              >
                <EditIcon mr={2} />
                {status === StatusEnum.DRAFT ? 'Continue Request' : 'Update'}
              </Button>
            </Box>
          </div>

          {status === StatusEnum.INPROGRESS && (
            <Button
              onClick={() => handleWithdrawRequest()}
              size={buttonSize}
              colorScheme='red'
              leftIcon={<DeleteIcon h={'20px'} w={'20px'} />}
            >
              Withdraw Request
            </Button>
          )}
        </Flex>
      </LoadingOverlay>
    </Card>
  );
};

export default AccommodationInfoHeader;
