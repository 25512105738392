import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Flex,
    HStack,
    Text,
    useBreakpointValue,
} from "@chakra-ui/react";
import LoadingOverlay from "components/loader/LoadingOverlay";
import accommodationStore from "store/dataStore/accommodationStore";
import { observer } from "mobx-react";

interface ISubQuestionProps {
    questions: any;
    [x: string]: any;
}

const IntervalSubQuestions: React.FC<ISubQuestionProps> = (props) => {
    const buttonSize = useBreakpointValue({ base: "md", lg: "lg" }, {
        ssr: false,
    } as any);

    const { questions } = props;
    const [answers, setAnswers] = useState({} as any);
    const [allowNextStep, setAllowNextStep] = useState(false);


    useEffect(() => {
        const ansObjectKeys = Object.keys(answers);
        if (ansObjectKeys.length === questions.length && ansObjectKeys.length > 0) {
            setAllowNextStep(true);
        }

    }, [answers, questions.length]);

    const handleQuestionResponse = (questionId: string, value: number) => {
        setAnswers({
            ...answers,
            [questionId]: value,
        });
    };

    const handleFormSubmit = () => {
        props.onSubmit(answers);
    };

    return (
        <LoadingOverlay isLoading={accommodationStore.loading}>
            <Flex direction={'column'} justifyContent={'center'} alignItems={'center'}>
                {questions.map((question: any, index: any) => {
                    const id = question.id;
                    let answer;
                    if (answers.hasOwnProperty(id)) {
                        answer = answers[id];
                    }

                    return (
                        <Box key={id} py={10}>
                            <Text align={'center'} fontSize={{ base: 'lg', lg: 'xl' }} >
                                {question.question_text}
                            </Text>
                            <HStack pt={5} justifyContent={'center'}>
                                <Button borderRadius={'50%'} variant={answer === 1 ? 'brand' : 'outline'} colorScheme={'brand'} onClick={() => handleQuestionResponse(question.id, 1)}>1</Button>
                                <Button borderRadius={'50%'} variant={answer === 2 ? 'brand' : 'outline'} colorScheme={'brand'} onClick={() => handleQuestionResponse(question.id, 2)}>2</Button>
                                <Button borderRadius={'50%'} variant={answer === 3 ? 'brand' : 'outline'} colorScheme={'brand'} onClick={() => handleQuestionResponse(question.id, 3)}>3</Button>
                                <Button borderRadius={'50%'} variant={answer === 4 ? 'brand' : 'outline'} colorScheme={'brand'} onClick={() => handleQuestionResponse(question.id, 4)}>4</Button>
                                <Button borderRadius={'50%'} variant={answer === 5 ? 'brand' : 'outline'} colorScheme={'brand'} onClick={() => handleQuestionResponse(question.id, 5)}>5</Button>
                            </HStack>
                        </Box>
                    );
                })}
                <Button
                    onClick={() => handleFormSubmit()}
                    disabled={!allowNextStep}
                    size={buttonSize}
                    colorScheme={'brand'}
                    variant={'link'}
                >
                    Submit
                </Button>
            </Flex>
        </LoadingOverlay>
    );

};

export default observer(IntervalSubQuestions);
