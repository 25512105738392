import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useEffect } from 'react';

import CustomComplexTable from '../../../components/tables/CustomComplexTable';
import {
  Box,
  Button,
  Flex,
  Grid,
  IconButton,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from '../../../components/card/Card';

import { Accommodation } from '../../../store/models/accommodation';
import { DeleteIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import accommodationRequestStore from '../../../store/dataStore/accommodationRequestStore';
import LoadingOverlay from '../../../components/loader/LoadingOverlay';
import { AccommodationRequestStatus } from '../../../utils/AccommodationRequestStatus';
import { StatusEnum } from 'store/models/status';

type AccommodationRequestObject = {
  id: string;
  accommodations: Accommodation[];
  caseManager: string;
  status: string;
  createdAt: string;
  updatedAt: string;
};

const ListAccommodationRequests = () => {
  const history = useHistory();
  const columnHelper = createColumnHelper<AccommodationRequestObject>();

  useEffect(() => {
    accommodationRequestStore
      .getAccommodationRequests()
      .then()
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const renderAccommodationNames = (accommodations: Accommodation[]) => {
    return accommodations.map((accommodation) => {
      return (
        <Text
          key={accommodation.id}
          color={textColor}
          fontSize={{ base: 'md', md: 'lg' }}
          fontWeight='500'
        >
          {accommodation.name}
        </Text>
      );
    });
  };
  const columns = [
    columnHelper.accessor('id', {
      id: 'accommodationRequestId',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '12px' }} color={textColor}>
          Tickets
        </Text>
      ),
      cell: (info: any) => {
        return (
          <>
            <Button
              variant={'link'}
              colorScheme={'brand'}
              onClick={() => handleViewAccommodation(info.row.original.id)}
            >
              #{info.getValue()}
            </Button>
            {renderAccommodationNames(info.row.original.accommodations)}
          </>
        );
      },
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '12px' }} color={textColor}>
          STATUS
        </Text>
      ),
      cell: (info) => {
        const displayStatus =
        AccommodationRequestStatus[info.getValue() as StatusEnum];

        return (
          <Flex align='center' gap={2}>
            {displayStatus?.icon}
            <Text
                color={textColor}
                fontSize={{ base: 'md', md: 'lg' }}
                fontWeight='500'
            >
              {displayStatus?.title}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('caseManager', {
      id: 'caseManager',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '12px' }} color={textColor}>
          CASE MANAGER
        </Text>
      ),
      cell: (info: any) => (
        <Text
          color={textColor}
          fontSize={{ base: 'md', md: 'lg' }}
          fontWeight='500'
        >
          {info.getValue()
            ? `${info.getValue().firstName} ${info.getValue().lastName}`
            : 'Not Assigned'}
        </Text>
      ),
    }),
    columnHelper.accessor('updatedAt', {
      id: 'updatedAt',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '12px' }} color={textColor}>
          Modified
        </Text>
      ),
      cell: (info) => (
        <Text
          color={textColor}
          fontSize={{ base: 'md', md: 'lg' }}
          fontWeight='500'
        >
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.display({
      id: 'actions',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '12px' }} color={textColor}>
          Actions
        </Text>
      ),
      cell: (data) => {
        return (
          <Flex gap={2}>
            <IconButton
              aria-label={'View Accommodation Request'}
              variant='outline'
              colorScheme='brand'
              borderRadius='10px'
              fontSize={20}
              onClick={() => handleViewAccommodation(data.row.original.id)}
              icon={<ViewIcon />}
            />
            <IconButton
              aria-label={'Edit Accommodation Request'}
              variant='outline'
              colorScheme='navy'
              borderRadius='10px'
              fontSize={20}
              onClick={() => handleUpdateAccommodation(data.row.original.id)}
              icon={<EditIcon />}
            />
            <IconButton
              aria-label={'Delete Accommodation Request'}
              variant='outline'
              colorScheme='red'
              borderRadius='10px'
              fontSize={20}
              onClick={() => alert('delete: #' + data.row.original.id)}
              icon={<DeleteIcon />}
            />
          </Flex>
        );
      },
    }),
  ];

  const handleViewAccommodation = (id: string) => {
    history.push(`/employee/view/requests/${id}`);
  };

  const handleUpdateAccommodation = (id: string) => {
    history.push({
      pathname: `/employee/view/requests/${id}`,
      state: { update: true },
    });
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Box pt={{ base: '180px', md: '150px', xl: '100px' }}>
      <LoadingOverlay
        isLoading={accommodationRequestStore.loading}
        message={'Fetching all Accommodation Tickets'}
      >
        <Grid gap={{ base: '20px', xl: '20px' }}>
          <Card
            flexDirection='column'
            w='100%'
            px='0px'
            overflowX={{ base: 'scroll', sm: 'scroll', lg: 'hidden' }}
          >
            <CustomComplexTable
              columns={columns}
              tableData={accommodationRequestStore.accommodationRequests}
            />
          </Card>
        </Grid>
      </LoadingOverlay>
    </Box>
  );
};

export default observer(ListAccommodationRequests);
