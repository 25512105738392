import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import DefaultAuth from 'layouts/auth/Default';
import illustration from 'assets/img/auth/auth.png';
import userStore from 'store/dataStore/userStore';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { observer } from 'mobx-react';
import { RiEyeCloseLine } from 'react-icons/ri';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import PasswordResetCompleteScreen from '../components/PasswordResetCompleteScreen';

interface IResetPasswordFormData {
  email: string;
  token: string;
  password: string;
  passwordConfirmation: string;
}

function ResetPassword() {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const email = params.get('email');

  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.500';
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const textColorInput = useColorModeValue('secondaryGray.900', 'white');
  const handleClick = (item: string): any => item === 'password' ? setShow(!show) : setShow2(!show2);

  if (!email || !token) {
    history.push('/auth');
  }

  useEffect(() => {
    userStore.passwordResetComplete = false;
  }, [])


  const onSubmit: SubmitHandler<IResetPasswordFormData> = async (resetPasswordFormData) => {
    resetPasswordFormData = {
      ...resetPasswordFormData,
      token: token,
      email: email,
    }
    await userStore.resetPassword(resetPasswordFormData);
  };

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isSubmitting }
  } = useForm<IResetPasswordFormData>();
  const password = useRef({});
  password.current = watch("password", "");

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      {userStore.passwordResetComplete ?
        <PasswordResetCompleteScreen />
        :
        <Flex
          maxW={{ base: '100%', md: 'max-content' }}
          w='100%'
          mx={{ base: 'auto', lg: '0px' }}
          me='auto'
          h='100%'
          alignItems='start'
          justifyContent='center'
          mb={{ base: '30px', md: '60px' }}
          px={{ base: '25px', md: '0px' }}
          mt={{ base: '40px', md: '10vh' }}
          flexDirection='column'>
          <Box me='auto'>
            <Heading color={textColor} fontSize='36px' mb='10px'>
              Reset your password
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColorSecondary}
              fontWeight='400'
              fontSize='md'>
              Enter your current password and new passwrod to reset your password.
            </Text>
          </Box>
          <Flex
            zIndex='2'
            direction='column'
            w={{ base: '100%', md: '420px' }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            mx={{ base: 'auto', lg: 'unset' }}
            me='auto'
            mb={{ base: '20px', md: 'auto' }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl isInvalid={!!errors.email ?? true} mb='24px'>
                <FormLabel
                  htmlFor='email'
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                  Email<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  id='email'
                  name='email'
                  fontSize='sm'
                  color={textColorInput}
                  focusBorderColor='#207077'
                  ms={{ base: '0px', md: '0px' }}
                  type='email'
                  placeholder='mail@simmmple.com'
                  fontWeight='500'
                  size='lg'
                  isDisabled={userStore.loading || isSubmitting}
                  {...register('email', {
                    required: 'This is required',
                  })}
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.password ?? true} mb='24px'>
                <FormLabel
                  htmlFor='password'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Password<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    id='password'
                    name='password'
                    fontSize='sm'
                    placeholder='Min. 8 characters'
                    size='lg'
                    color={textColorInput}
                    focusBorderColor='#207077'
                    type={show ? 'text' : 'password'}
                    isDisabled={userStore.loading || isSubmitting}
                    colorScheme='auth'
                    {...register('password', {
                      required: 'This is required',
                      minLength: { value: 8, message: 'Minimum length should be 8 characters' }
                    })}
                  />
                  <InputRightElement display='flex' alignItems='center' mt='4px'>
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: 'pointer' }}
                      as={!show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={() => handleClick('password')}
                    />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  {errors.password && errors.password.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.passwordConfirmation ?? true} mb='24px'>
                <FormLabel
                  htmlFor='passwordConfirmation'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Confirm Password<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    id='passwordConfirmation'
                    name='passwordConfirmation'
                    fontSize='sm'
                    color={textColorInput}
                    // placeholder='Min. 8 characters'
                    size='lg'
                    focusBorderColor='#207077'
                    type={show2 ? 'text' : 'password'}
                    isDisabled={userStore.loading || isSubmitting}
                    colorScheme='auth'
                    {...register('passwordConfirmation', {
                      required: 'This is required',
                      validate: value =>
                        value === password.current || "The passwords do not match"
                    })}
                  />
                  <InputRightElement display='flex' alignItems='center' mt='4px'>
                    <Icon
                      color={textColorInput}
                      _hover={{ cursor: 'pointer' }}
                      as={!show2 ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={() => handleClick('passwordConfirmation')}
                    />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  {errors.passwordConfirmation && errors.passwordConfirmation.message}
                </FormErrorMessage>
              </FormControl>

              <Flex justifyContent='space-between' align='center' mb='24px'>
                <NavLink to='/auth/login'>
                  <Text
                    color={textColorBrand}
                    fontSize='sm'
                    w='124px'
                    fontWeight='500'>
                    &lt; Back to sign in
                  </Text>
                </NavLink>
              </Flex>
              <Button
                type='submit'
                fontSize='sm'
                colorScheme='brand'
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'
                isLoading={userStore.loading || isSubmitting}>
                Reset Password
              </Button>
            </form>
          </Flex>
        </Flex>
      }
    </DefaultAuth >
  );
}

export default observer(ResetPassword);
