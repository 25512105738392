import {
    Flex,
    Heading,
    Icon,
    Text,
    Link,
    useColorModeValue
} from '@chakra-ui/react';
import { MdLocationOff } from 'react-icons/md';
import { observer } from 'mobx-react';
import DefaultAuth from 'layouts/auth/Default';
import illustration from 'assets/img/auth/auth.png';
import { useHistory } from 'react-router-dom';

const NotFound = () => {
    const textColorBrand = useColorModeValue('brand.500', 'white');
    const history = useHistory();

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: '100%', md: 'max-content' }}
                w='100%'
                mx={{ base: 'auto', lg: '0px' }}
                me='auto'
                h='100%'
                alignItems='center'
                justifyContent='center'
                mb={{ base: '30px', md: '60px' }}
                px={{ base: '25px', md: '0px' }}
                mt={{ base: '40px', md: '10vh' }}
                flexDirection='column'>
                <Icon color='brand.600' as={MdLocationOff} h={100} w={100} />
                <Heading>404</Heading>
                <Text fontSize={'4xl'}> Page not found </Text>
                <Link onClick={() => history.goBack()}>
                    <Text
                        color={textColorBrand}
                        fontSize='lg'
                        fontWeight='500'>
                        &lt; Go Back
                    </Text>
                </Link>
            </Flex>
        </DefaultAuth >
    );
}

export default observer(NotFound);
