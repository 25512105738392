import { observable, action, makeObservable, runInAction } from 'mobx'
import {
    UserCreateRequest,
    User,
    UserLoginRequest,
    UserForgotPasswordRequest,
    UserResetPasswordRequest,
} from 'store/models/user';
import { request } from 'store/axios';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import getErrorFromStatusCode from 'store/utils/axiosErrorUtils';
import { UpdateProfileDTO } from 'store/models/profile';
import * as Constants from '../../utils/Constants';

export enum UserEndPoints {
    REGISTER = '/wpas/auth/register',
    LOGIN = '/wpas/auth/login',
    REFRESH_TOKEN = '/api/wpas/auth/refresh-token',
    LOGOUT = '/wpas/auth/logout',
    FORGOT_PASSWORD = '/wpas/auth/forgot-password',
    RESET_PASSWORD = '/wpas/auth/reset-password',
    GET_USER = '/api/wpas/user',
    GET_USER_PROFILE = '/api/wpas/user/profile',
    AVATAR_POST = '/api/wpas/user/avatar',
    PROFILE_PATCH = '/api/wpas/user/profile',
    POST_GENERATE_EMPLOYEE_PROFILE = '/api/wpas/generate/employee-profile',
}

const requestOptionForFormData: AxiosRequestConfig = {
    headers: { "Content-Type": "multipart/form-data" }
};

class UserStore {
    @observable loading: boolean = false;
    @observable loadingMessage: string = '';
    @observable user: User = null;
    @observable avatarUrl: string;
    @observable errorObject: any = null;
    @observable registrationComplete: boolean = true;
    @observable passwordResetReqComplete: boolean = false;
    @observable passwordResetComplete: boolean = false;
    UserEndPoints = {
        REGISTER: '/wpas/auth/register',
        LOGIN: '/wpas/auth/login',
        LOGOUT: '/wpas/auth/logout',
        FORGOT_PASSWORD: '/wpas/auth/forgot-password',
        RESET_PASSWORD: '/wpas/auth/reset-password',
        GET_USER: '/api/wpas/user',
        GET_USER_PROFILE: '/api/wpas/user/profile',
        AVATAR_POST: '/api/wpas/user/avatar',
        PROFILE_PATCH: '/api/wpas/user/profile',
        POST_GENERATE_EMPLOYEE_PROFILE: '/api/wpas/generate/employee-profile',
    }

    constructor() {
        makeObservable(this);
    }

    @action
    setErrors = (errorObject: any) => {
        this.errorObject = errorObject;
    }

    @action
    setLoading = (value: boolean = true, message: string = '') => {
        this.loading = value;

        this.setLoadingMessage(message);
    }

    @action
    setLoadingMessage = (message: string = '') => {
        this.loadingMessage = message;
    }

    @action
    setUser = (user: User) => {
        this.user = user
    }

    register = async (registerUserData: UserCreateRequest) => {
        this.setLoading();

        try {
            const response = await request.post<AxiosResponse>(UserEndPoints.REGISTER, registerUserData as any);

            return response.data;
        } catch (error: AxiosError | any) {
            return Promise.reject(error.response)
        } finally {
            this.setLoading(false);
        }
    }

    login = async (loginData: UserLoginRequest) => {
        this.setLoading();

        try {
            const response = await request.post<AxiosResponse>(UserEndPoints.LOGIN, loginData, null);

            localStorage.setItem(
                Constants.LOCAL_STORAGE_USER_LOGGED_IN_KEY,
                'true'
            );

            this.setLocalStorage(response.data);

            return response.data;
        } catch (error: AxiosError | any) {
            return Promise.reject(error.response)
        } finally {
            this.setLoading(false);
        }
    }

    refreshToken = async () => {
        this.setLoading();

        try {
            const response = await request.post<AxiosResponse>(UserEndPoints.REFRESH_TOKEN, {}, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('refresh_token')
                }
            });

            this.setLocalStorage(response.data)

            return response.data;
        } catch (error: AxiosError | any) {
            return Promise.reject(error.response)
        } finally {
            this.setLoading(false);
        }
    }

    logout = async () => {
        this.setLoading(true, "Logging out");
        try {
            await request.post<AxiosResponse>(UserEndPoints.LOGOUT);
        } catch (error: AxiosError | any) {
            return Promise.reject(error.response)
        } finally {
            this.setLoading(false);
        }
    }

    @action
    forgotPassword = async (forgotPasswordData: UserForgotPasswordRequest) => {
        this.setLoading();
        try {
            await request.post<AxiosResponse>(UserEndPoints.FORGOT_PASSWORD, forgotPasswordData);

            this.passwordResetReqComplete = true;
        } catch (err: AxiosError | any) {
            this.setErrors({ error: true, message: getErrorFromStatusCode(err) });
        } finally {
            this.setLoading(false);
        }
    }

    @action
    resetPassword = async (resetPasswordData: UserResetPasswordRequest) => {
        this.setLoading();
        try {
            await request.post<AxiosResponse>(UserEndPoints.RESET_PASSWORD, resetPasswordData);

            this.passwordResetComplete = true;
        } catch (err: AxiosError | any) {
            this.setErrors({ error: true, message: getErrorFromStatusCode(err) });
        } finally {
            this.setLoading(false);
        }
    }

    getUser = async () => {
        try {
            const response = await request.get<AxiosResponse>(UserEndPoints.GET_USER);
            this.setUser(response.data.data);
        } catch (error: AxiosError | any) {
            return Promise.reject(error.response);
        }
    }

    @action
    getUserProfile = async () => {
        try {
            const response = await request.get<AxiosResponse>(UserEndPoints.GET_USER_PROFILE);
            const data = response.data.data;

            const { profile, ...userData } = data;
            this.setUser(userData);

            return data;
        } catch (error: AxiosError | any) {
            return Promise.reject(error.response);
        }
    }

    @action
    uploadAvatar = async (avatar: File) => {
        try {
            let avatarPost = new FormData();
            avatarPost.append('avatar', avatar);
            avatarPost.append('userId', this.user.id);
            await request.post<AxiosResponse>(UserEndPoints.AVATAR_POST, avatarPost, requestOptionForFormData);
        } catch (err: AxiosError | any) {
            this.setErrors({ error: true, message: getErrorFromStatusCode(err) });
        }
    }

    @action
    updateProfile = async (data: UpdateProfileDTO) => {
        try {
            await request.patch<AxiosResponse>(UserEndPoints.PROFILE_PATCH, data);
        } catch (err: AxiosError | any) {
            this.setErrors({ error: true, message: getErrorFromStatusCode(err)});
        }
    }

    downloadEmployeeProfile = async () => {
        try {
            const response = await request.post<AxiosResponse>(UserEndPoints.POST_GENERATE_EMPLOYEE_PROFILE, {});
            return response.data as any;
        } catch (error: AxiosError | any) {
            return Promise.reject(error.response);
        }
    }

    private setLocalStorage(response: any) {
        localStorage.setItem(
            'access_token',
            response.access_token
        );
        localStorage.setItem(
            'refresh_token',
            response.refresh_token
        );
        localStorage.setItem(
            'token_expiry_time',
            response.token_expiry_time
        );
    }
}

const userStore = new UserStore();
export default userStore;
