import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { observer } from 'mobx-react';
import { RiEyeCloseLine, RiKeyFill } from 'react-icons/ri';
import DefaultAuth from 'layouts/auth/Default';
import illustration from 'assets/img/auth/auth.png';
import userStore from 'store/dataStore/userStore';
import * as Constants from '../../../utils/Constants';

interface ILoginFormData {
  email: string;
  password: string;
}

const Login = () => {
  const history = useHistory();
  const [show, setShow] = React.useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<ILoginFormData>();

  const handleClick = () => setShow(!show);

  const onSubmit: SubmitHandler<ILoginFormData> = async (loginFormData) => {
    userStore
      .login(loginFormData)
      .then((response: any) => {

        history.push('/employee');
      })
      .catch((error) => {
        userStore.setErrors({
          status: error.status,
          message: error.data.message,
        });
      });
  };

  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.500';
  const textColorInput = useColorModeValue('secondaryGray.900', 'white');
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: '100%', md: '900px' }}
        w='100%'
        h='100%'
        mx={{ base: 'auto', lg: '0px' }}
        alignItems={'center'}
        justifyContent='center'
        me='auto'
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '10vh' }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading
            color={textColor}
            textAlign='center'
            fontSize='36px'
            mb='20px'>
            Welcome to the Workplace Accommodation System
          </Heading>
          <Text
            px={{ base: '0', md: '50px' }}
            pb={{ base: '0', md: '50px' }}
            mb='36px'
            ms='4px'
            fontWeight='400'
            fontSize='md'
            textAlign='center'
            color={textColorSecondary}>
            W-PAS is an innovative Workplace Accommodation System designed to
            streamline and automate managing and providing accommodations for
            employees with disabilities, fostering inclusivity and efficiency in
            the workplace.
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: '100%', md: '420px' }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: 'auto', lg: 'unset' }}
          me='auto'
          mb={{ base: '20px', md: 'auto' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={!!errors.email ?? true} mb='24px'>
              <FormLabel
                htmlFor='email'
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Email Address<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                id='email'
                name='email'
                color={textColorInput}
                colorScheme='brand'
                focusBorderColor='#207077'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                type='email'
                placeholder='mail@simmmple.com'
                fontWeight='500'
                size='lg'
                isDisabled={userStore.loading || isSubmitting}
                {...register('email', {
                  required: 'This is required',
                })}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.password ?? true} mb='24px'>
              <FormLabel
                htmlFor='password'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  id='password'
                  name='password'
                  fontSize='sm'
                  placeholder='Min. 8 characters'
                  size='lg'
                  focusBorderColor='#207077'
                  type={show ? 'text' : 'password'}
                  isDisabled={userStore.loading || isSubmitting}
                  color={textColorInput}
                  colorScheme='auth'
                  {...register('password', {
                    required: 'This is required',
                  })}
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: 'pointer' }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </FormControl>
            <Flex justifyContent='space-between' align='center' mb='24px'>
              <NavLink to='/auth/forgot-password'>
                <Text
                  color={textColorBrand}
                  fontSize='sm'
                  fontWeight='500'>
                  Forgot password?
                </Text>
              </NavLink>

              <NavLink to='/auth/register'>
                <Text
                  color={textColorBrand}
                  fontSize='sm'
                  fontWeight='500'>
                  Create an Account
                </Text>
              </NavLink>
            </Flex>
            <Button
              type='submit'
              fontSize='sm'
              colorScheme='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              isLoading={userStore.loading || isSubmitting}>
              Sign In
            </Button>
          </form>

          <Flex
            flexDirection='column'
            gap={2}
            textAlign='center'
            justifyContent={'center'}
            alignItems={'center'}>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              By Signing in, I agree to the Magnify Access
              <Link
                isExternal
                aria-label='Privacy Statement'
                href='https://www.magnifyaccess.ca/privacy-policy'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Privacy Statement
                </Text>
              </Link>
              and
              <Link
                isExternal
                aria-label='Terms of Services'
                href='https://www.magnifyaccess.ca/terms'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Terms of Services
                </Text>
              </Link>
            </Text>

            <Box pt={5}>
              <IconButton
                size={'lg'}
                variant={'outline'}
                aria-label='Single Sign On'
                icon={<Icon as={RiKeyFill} />}
              />
              <Text
                color={textColorDetails}
                fontWeight='400'
                fontSize='14px'
                pt={2}>
                SSO
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
};

export default observer(Login);
