import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import { axiosBaseOptions } from 'store/axios/axios-setup';
import userStore, { UserEndPoints } from '../dataStore/userStore';
import * as Constants from '../../utils/Constants';

class MyAxios {
  private readonly axiosInstance: AxiosInstance
  constructor(options: AxiosRequestConfig) {
    this.axiosInstance = axios.create(options)
    this.initInterceptors();
  }

  private initInterceptors() {
    //Add Bearer Token, Implement logic for refresh token
    this.axiosInstance.defaults.withCredentials = true;
    this.axiosInstance.interceptors.request.use(
      async (config: AxiosRequestConfig | any) => {

        const tokenExpiryTime = new Date(localStorage.getItem('token_expiry_time'));
        const currentTime = new Date();

        const accessToken = localStorage.getItem('access_token');
        const refreshToken = localStorage.getItem('refresh_token');

        if (accessToken && refreshToken && tokenExpiryTime < currentTime && config.url !== UserEndPoints.REFRESH_TOKEN) {
            await userStore.refreshToken();
        }

      // If token exists, add it to the Authorization header
      if (accessToken && config.url !== UserEndPoints.REFRESH_TOKEN) {
          config.headers['Authorization'] = `Bearer ${accessToken}`;
      }

        return config;
      },
      (error) => {
        return Promise.reject(error)
      }
    );
    this.axiosInstance.interceptors.response.use(
        (response: AxiosResponse) => {
          userStore.errorObject = null;
          return response;
        },
        (error) => {
          //TODO::MODIFY LOGIC AND AVOID HARD CODING PATH HERE
          if (
              error.response.status === Constants.HTTP_UNAUTHORIZED
              && error.response.config.url !== userStore.UserEndPoints.LOGIN
          ) {
            localStorage.setItem(Constants.LOCAL_STORAGE_USER_LOGGED_IN_KEY, 'false');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('access_token');
            localStorage.removeItem('token_expiry_time');
            window.location.href = '/auth/login';
          }
          return Promise.reject(error);
        }
    );
  }

  get<T = any>(url: string, data?: object): Promise<AxiosResponse<T>> {
    return this.axiosInstance.get(url, { params: data });
  }

  post<T = any>(url: string, data?: object, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axiosInstance.post(url, data, config);
  }

  patch<T = any>(url: string, data?: object): Promise<AxiosResponse<T>> {
    return this.axiosInstance.post(url + '?_method=PATCH', data);
  }

  put<T = any>(url: string, data?: object): Promise<AxiosResponse<T>> {
    return this.axiosInstance.put(url, data);
  }

  delete<T = any>(url: string, data?: object): Promise<AxiosResponse<T>> {
    return this.axiosInstance.delete(url, data);
  }
}

export const request = new MyAxios(axiosBaseOptions)
