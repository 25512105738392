import { Redirect, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import React from 'react';

import * as Constants from '../utils/Constants';

interface props {
    path: string,
    component: React.ReactNode | any;
}

const SecuredRoute: React.FC<props> = (props) => {
    const { component: Component, ...rest } = props;

    const userIsLogged = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_USER_LOGGED_IN_KEY));

    return (
        <Route
            {...rest}
            render={(props) => userIsLogged
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/auth', state: { from: props.location } }} />} />
    );
}

export default observer(SecuredRoute);
