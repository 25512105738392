import { Input, useColorModeValue } from '@chakra-ui/react';

interface IAvatarProps {
  [x: string]: any;
}

const InputDefaultText: React.FC<IAvatarProps> = ({register, name, ...rest}) => {
  const textColorInput = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Input
      color={textColorInput}
      colorScheme="brand"
      focusBorderColor="#207077"
      {...rest}
      {...(register && register(name))}
    />
  );
};

export default InputDefaultText;
