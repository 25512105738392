import { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import routes from 'routes/routes';
import { SidebarContext } from 'contexts/SidebarContext';
import {
  Box,
  Flex,
  Image,
  Link,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import userStore from 'store/dataStore/userStore';
import wpasImage from 'assets/img/wpas/wpasLogoNew.svg';

export default observer(function Auth() {
  const toast = useToast();
  const [toggleSidebar, setToggleSidebar] = useState(false);

  useEffect(() => {
    if (userStore.errorObject) {
      toast({
        title: 'Error occurred',
        description: userStore.errorObject.message,
        status: 'error',
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
    }
  }, [userStore.errorObject, toast]);

  const getRoutes = (routes: RoutesType[]) => {
    return routes.map((route, key) => {
      if (route.layout === '/auth') {
        return (
          <Route
            path={route.layout + route.path}
            component={route.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const textColor = useColorModeValue('navy.700', 'white');
  const shadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.12)',
    'none'
  );
  const authBg = useColorModeValue('white', 'navy.900');
  document.documentElement.dir = 'ltr';

  return (
    <Box>
      <SidebarContext.Provider value={{ toggleSidebar, setToggleSidebar }}>
        <Box
          bg={authBg}
          float='right'
          minHeight='100vh'
          height='100%'
          position='relative'
          w='100%'
          transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
          transitionDuration='.2s, .2s, .35s'
          transitionProperty='top, bottom, width'
          transitionTimingFunction='linear, linear, ease'>
          <Box mx='auto' minH='100vh'>
            <Flex
              boxShadow={shadow}
              bg={authBg}
              justifyContent='space-between'
              alignItems={'center'}
              h='80px'
              px={{ base: '10px', md: '50px' }}>
              <Link
                isExternal
                aria-label='Magnify Access Website'
                href='https://www.magnifyaccess.ca/'>
                <Image alt='wpas-logo' maxWidth='120px' src={wpasImage} />
              </Link>

              <Link href='/'>
                <Text color={textColor} as='b' fontSize='xl'>
                  W-PAS | Employee Portal
                </Text>
              </Link>
            </Flex>
            <Switch>
              {getRoutes(routes)}
              <Redirect from='/auth' to='/auth/login' />
            </Switch>
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
});
