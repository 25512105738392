import { createStandaloneToast } from '@chakra-ui/react';

const toast = createStandaloneToast();

export const displayErrorToast = (message: string = 'Unknown Error') => {
    toast({
        title: 'Error occurred',
        description: message,
        status: 'error',
        duration: 5000,
        position: 'bottom-right',
        isClosable: true,
    });
}
