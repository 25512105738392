import { useEffect } from 'react';
import { Box, Button, Stack, Icon, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { MdLibraryAdd, MdOutlineHistory } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import accommodationStore from 'store/dataStore/accommodationStore';

type ConfirmationProps = {
    [x: string]: any;
};

const Confirmation: React.FC<ConfirmationProps> = (props) => {
    const history = useHistory();
    const textColor = useColorModeValue('navy.700', 'white');
    const textColorSecondary = 'gray.500';

    useEffect(() => {
        return () => {
            accommodationStore.setRequestCompleteStatus(false);
        };
    }, []);

    const handleClick = (): void => {
        accommodationStore.setRequestCompleteStatus(false);
    }

    const buttonSize = useBreakpointValue({ base: 'md', lg: 'lg' }, { ssr: false } as any);

    return (
        <>
            <Box>
                <Text fontWeight={'bold'} color={textColor} align={'center'} px={{ base: '0', lg: '15%' }} fontSize={{ base: 'xl', lg: '2xl' }} mb='10px'>
                    Thank you for submitting your accommodation request
                </Text>
                <Text color={textColorSecondary} align={'center'} px={{ base: '0', lg: '15%' }} fontSize={{ base: 'lg', lg: 'xl' }}>
                    You will receive an email confirmation with details shortly.
                </Text>
                <Stack py={5} justifyContent={'center'} direction={['column', 'column', 'column', 'row']}>
                    <Button onClick={() => { handleClick(); history.push('/employee/accommodation-requests') }} size={buttonSize} colorScheme='gray' variant='outline' leftIcon={<Icon boxSize={6} color='inherit' as={MdOutlineHistory} />}>
                        Previous Requests
                    </Button>
                    <Button onClick={() => { handleClick(); history.push('/employee/accommodation/new-accommodation') }} size={buttonSize} variant='brand' leftIcon={<Icon boxSize={6} as={MdLibraryAdd} />}>
                        New Request
                    </Button>
                </Stack>
            </Box>
        </>
    );
}

export default observer(Confirmation);
