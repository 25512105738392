import { observable, action, makeObservable, runInAction } from 'mobx'
import { request } from 'store/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { AccommodationRequest } from '../models/accommodationRequest';
import { UpdateAccommodationRequest } from 'store/models/accommodation';
import { requestOptionForFormData } from 'store/axios/axios-setup';
import accommodationStore from './accommodationStore';
import { displayErrorToast } from 'utils/toastHelper';
import getErrorFromStatusCode from 'store/utils/axiosErrorUtils';

export enum Endpoints {
    GET_ACCOMMODATION_REQUESTS = '/api/wpas/accommodation-requests',
    GET_ACCOMMODATION_REQUEST = '/api/wpas/accommodation-requests/:id',
    PATCH_ACCOMMODATION_REQUEST_STATUS = '/api/wpas/accommodation-requests/:id/status',
    UPDATE_ACCOMMODATION_REQUEST = '/api/wpas/accommodation-requests/:id?_method=PATCH',
}

class AccommodationRequestStore {
    @observable loading: boolean = false;
    @observable accommodationRequests: AccommodationRequest[] = [];
    @observable accommodationRequest: AccommodationRequest | null;

    constructor() {
        makeObservable(this);
    }

    @action
    setLoading = (value: boolean = true) => {
        this.loading = value;
    }

    @action
    setAccommodationRequests = (accommodationRequests: AccommodationRequest[]) => {
        this.accommodationRequests = accommodationRequests
    }

    @action
    setAccommodationRequest = (accommodationRequest: AccommodationRequest) => {
        this.accommodationRequest = accommodationRequest
    }

    getAccommodationRequests = async () => {
        this.setLoading();
        this.setAccommodationRequests([]);
        try {
            const response = await request.get<AxiosResponse>(
                Endpoints.GET_ACCOMMODATION_REQUESTS
            );
            this.setAccommodationRequests(response.data.data);
        } catch (error: AxiosError | any) {
            return Promise.reject(error.response);
        } finally {
            this.setLoading(false);
        }
    };

    getAccommodationRequest = async (id: string) => {
        this.setLoading();
        this.setAccommodationRequest(null);
        try {
            const response = await request.get<AxiosResponse>(
                Endpoints.GET_ACCOMMODATION_REQUEST.replace(':id', id)
            );
            this.setAccommodationRequest(response.data.data);
        } catch (error: AxiosError | any) {
            return Promise.reject(error.response);
        } finally {
            this.setLoading(false);
        }
    };

    updateStatus = async (id: string, status: string) => {
        try {
            await request.patch<AxiosResponse>(Endpoints.PATCH_ACCOMMODATION_REQUEST_STATUS.replace(':id', id), { status: status });
        } catch (error: AxiosError | any) {
            displayErrorToast(getErrorFromStatusCode(error));
            return Promise.reject(error.response)
        }
    }

    @action
    updateAccommodationRequest = async (
        id: string,
        data: UpdateAccommodationRequest
    ) => {
        try {
            let formData = new FormData();
            formData.append('accommodationId', id);
            formData.append('note', data.note);
            if (data.attachments?.length > 0) {
                data.attachments.forEach((file: File) => {
                    formData.append('attachments[]', file);
                });
            }

            await request.post<AxiosResponse>(
                Endpoints.UPDATE_ACCOMMODATION_REQUEST.replace(':id', id),
                formData,
                requestOptionForFormData
            );
            accommodationStore.onSuccessWithFeedback('Request Successfully Completed');
            return true;
        } catch (err: AxiosError | any) {
            displayErrorToast(getErrorFromStatusCode(err));
        } finally { }
    };
}

const accommodationRequestStore = new AccommodationRequestStore();
export default accommodationRequestStore;
