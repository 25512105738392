import { Box, Button, Flex, Image, Link, Text, useColorModeValue } from '@chakra-ui/react';

export default function SidebarDocs() {
    const bgColor = 'linear-gradient(135deg, #868CFF 0%, #4318FF 100%)';
    const borderColor = useColorModeValue('white', 'navy.800');

    return (
        <Flex
            justify='center'
            direction='column'
            align='center'
            borderRadius='30px'
            me={ { base: '20px' } }
            position='relative'
		>
            <Box id={ 'google_translate_element' }></Box>
        </Flex>
    );
}
