import { Switch, Route, Redirect } from 'react-router-dom';
import React from 'react';

import 'react-international-phone/style.css';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import './assets/css/App.css';

import AuthLayout from './layouts/auth';
import EmployeeLayout from './layouts/employee';
import SecuredRoute from 'utils/SecuredRoute';
import NotFound from 'views/common/NotFound';
import useGoogleTranslateElement from 'hooks/useGoogleTranslateElement';
import * as Constants from './utils/Constants';

declare global {
  interface Window {
    google: any;
    googleTranslateElementInit: any;
    Pusher: any;
    Echo: any;
  }
}

const App = () => {
    useGoogleTranslateElement();

    //TODO::REFACTOR SECURED ROUTES AND THIS TO AVOID DUPLICATE CODE
    const AuthRoutes: React.FC<{
        path: string,
        component: React.ReactNode | any;
    }> = (props) => {
        const { component: Component, ...rest } = props;
        const userIsLogged = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_USER_LOGGED_IN_KEY));

        return (
            <Route
                { ...rest }
                render={
                    (props) => {
                        return !userIsLogged
                            ? <Component { ...props } />
                            : <Redirect to={ { pathname: '/employee', state: { from: props.location } } }/>
                    }
                }
            />
        );
    }

    return (
        <Switch>
            <Route exact
                path="/">
                <Redirect to="/auth"/>
            </Route>
            <Route exact path={'/auth/logout'} component={AuthLayout}/>
            <AuthRoutes
                path={ `/auth` }
                component={ AuthLayout }/>
            <SecuredRoute path={ `/employee` }
                component={ EmployeeLayout }/>
            <Route component={ NotFound }/>
        </Switch>
    );
}

export default App;
