import React, { useEffect, useState } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useLocation, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { MdInsertDriveFile } from 'react-icons/md';

import { Box, Button, Flex, List, ListItem, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';

import accommodationStore from 'store/dataStore/accommodationStore';
import AccommodationInfoHeader from '../components/accommodations/AccommodationInfoHeader';
import UpdateAccommodationRequestForm from './UpdateAccommodationRequestForm';
import accommodationRequestStore from '../../../store/dataStore/accommodationRequestStore';
import LoadingOverlay from '../../../components/loader/LoadingOverlay';

interface InfoHeaderData {
  status: string;
  caseManager: any;
  lastUpdated: string;
  accommodationName: any;
  id: string;
}

interface AccommodationRequestNote {
  id: string;
  note: string;
  attachments?: File[];
  createdAt?: string;
}

const AccommodationRequest = () => {
  const location = useLocation<{ update?: boolean }>();
  const { id } = useParams<{ id: string }>();
  const [isUpdating, setIsUpdating] = useState(location.state?.update || false);

  useEffect(() => {
    accommodationRequestStore.getAccommodationRequest(id).then().catch();
  }, [id]);

  let headerInfo = {
    status: '',
    caseManager: '',
    lastUpdated: '',
    accommodationName: '',
    id: '',
  };

  if (accommodationRequestStore.accommodationRequest) {
    headerInfo = {
      status: accommodationRequestStore.accommodationRequest.status,
      caseManager: accommodationRequestStore.accommodationRequest.caseManager,
      lastUpdated: accommodationRequestStore.accommodationRequest.updatedAt,
      accommodationName: accommodationRequestStore.accommodationRequest
        .accommodations.length
        ? accommodationRequestStore.accommodationRequest.accommodations[0].name
        : '',
      id: accommodationRequestStore.accommodationRequest.id,
    };
  }

  const handleOnUpdate = (status?: string) => {
    setIsUpdating(true);
  };

  const displayAttachments = (attachments: File[]) => {
    if (attachments.length > 0) {
      return (
        <Box>
          <List>
            {attachments.map((attachment: any) => (
              <ListItem key={attachment.id}>
                <Button
                  onClick={() =>
                    accommodationStore.getAttachment(attachment.id)
                  }
                  display={'flex'}
                  colorScheme={'brand'}
                  variant={'link'}
                >
                  <MdInsertDriveFile fontSize={20} />
                  {attachment.filename}
                </Button>
              </ListItem>
            ))}
          </List>
        </Box>
      );
    }
    return null;
  };

  const renderNotes = (notes: AccommodationRequestNote[]) => {
    return notes && notes.length ? (
      notes.map((note: any, index: any) => {
        const attachments = note.attachments.length > 0 ? note.attachments : [];
        return (
          <Card key={note.id} my={3} bg={'brand.100'}>
            <Box fontWeight={'bold'}>
              <Flex fontWeight={'bold'} justifyContent={'space-between'}>
                <Text>
                  {note.user?.firstName} {note.user?.lastName}
                </Text>
                <Text>{note.createdAt}</Text>
              </Flex>
            </Box>
            <Box
              maxH={'275px'}
              overflowY={'auto'}
              my={2}
              borderRadius='16px'
              borderWidth='1.5px'
              borderStyle='solid'
            >
              <ReactQuill
                className='quill-preview'
                key={index}
                value={note.note}
                readOnly={true}
                theme='bubble'
                style={{ minHeight: '40px !important' }}
              />
            </Box>
            {displayAttachments(attachments)}
          </Card>
        );
      })
    ) : (
      <Card
        my={3}
        bg={'brand.100'}
        textAlign={'center'}
        fontStyle={'italic'}
        fontSize={'md'}
      >
        Please update the request to add documentation or notes
      </Card>
    );
  };

  return (
    <Box pt={{ base: '180px', md: '150px', xl: '100px' }}>
      <LoadingOverlay isLoading={accommodationRequestStore.loading}>
        <AccommodationInfoHeader
          handleOnUpdate={handleOnUpdate}
          headerInfo={headerInfo}
        />
        <Card my={5}>
          <Text color={'secondaryGray.900'} fontSize={'xl'} fontWeight={'bold'}>
            Notes
          </Text>
          <Flex alignItems={'center'} direction='column'>
            {isUpdating === true ? (
              <UpdateAccommodationRequestForm
                requestStatus={
                  accommodationRequestStore.accommodationRequest?.status
                }
                setIsUpdating={setIsUpdating}
              />
            ) : (
              ''
            )}
            {renderNotes(
              toJS(accommodationRequestStore.accommodationRequest?.notes)
            )}
          </Flex>
        </Card>
      </LoadingOverlay>
    </Box>
  );
};

export default observer(AccommodationRequest);
