import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Alert, AlertIcon, Box, Flex, SimpleGrid, Text, useRadioGroup } from '@chakra-ui/react';

import LoadingOverlay from 'components/loader/LoadingOverlay';
import RadioCard from 'components/RadioCard/RadioCard';
import accommodationStore from 'store/dataStore/accommodationStore';

interface IDisabilitiesProps {
    [x: string]: any;
}

const Disabilities: React.FC<IDisabilitiesProps> = (props) => {
    const [disabilityId, setDisabilityId] = useState(null);

    useEffect(() => {
        accommodationStore.getDisabilities();
    }, []);

    const handleChange = (value: string) => {
        setDisabilityId(value);
        props.onDisabilitySelect({
           disabilityId: value
        });
    }

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'disability',
        value: disabilityId,
        onChange: (value: string) => { handleChange(value); },
    });

    const group = getRootProps();

    const renderDisabilities = (): React.ReactElement => {
            return (
                <>
                    <SimpleGrid alignItems="center" justifyContent="center" mb={'5'} {...group} columns={[1, 1, 2, 3]} spacing={5}>
                        {accommodationStore.disabilities.map((disability: any) => {
                            const { id: value, name } = disability;
                            const radio = getRadioProps({ value });
                            return (
                                <RadioCard key={value} {...radio}>
                                    {name}
                                </RadioCard>
                            );
                        })}
                    </SimpleGrid>
                    {
                        !accommodationStore.disabilities.length ?
                            <Alert status='warning'>
                                <AlertIcon />
                                No Disabilities found.
                            </Alert>
                            : ''
                    }
                </>
            );
    }

    return (
        <LoadingOverlay isLoading={accommodationStore.loading}>
            <Box>
                <Text px={{ base: '0', lg: '15%' }} fontSize={{ base: 'xl', lg: '2xl' }} textAlign={'center'} fontWeight='bold'>
                    Please select which disability you are experiencing a functional impact in completing your role responsibilities:
                </Text>
                <Flex mt={'20px'} justifyContent={'center'}>
                    <div>
                        {renderDisabilities()}
                    </div>
                </Flex>
            </Box>
        </LoadingOverlay>
    );
}

export default observer(Disabilities);
