interface Address {
  addressLine1: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
}

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

interface AddressResponse {
  address_components: AddressComponent[];
  formatted_address: string;
  // Add other properties from the response if needed
}

export const extractAddressFields = (response: AddressResponse): Address => {
  const { address_components } = response;

  const streetNumberComponent = address_components.find((component) =>
    component.types.includes('street_number')
  );

  const routeComponent = address_components.find((component) =>
    component.types.includes('route')
  );

  const addressLine1 =
    (streetNumberComponent ? streetNumberComponent.long_name + ' ' : '') +
    (routeComponent ? routeComponent.long_name : '');

  const city =
    address_components.find((component) => component.types.includes('locality'))
      ?.long_name || '';

  const state =
    address_components.find((component) =>
      component.types.includes('administrative_area_level_1')
    )?.short_name || '';

  const country =
    address_components.find((component) => component.types.includes('country'))
      ?.long_name || '';

  const postalCode =
    address_components.find((component) =>
      component.types.includes('postal_code')
    )?.long_name || '';

  return {
    addressLine1,
    city,
    state,
    country,
    postalCode,
  };
};
