import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  CloseButton,
  Flex,
  HStack,
  Icon,
  IconButton,
  SimpleGrid,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import TableWithRemoveAction from 'components/tables/CustomSimpleTable';
import Upload from 'components/upload/Upload';
import { createColumnHelper } from '@tanstack/react-table';
import Moment from 'react-moment';
import { FileRejection } from 'react-dropzone';
import { getFormatterStringFromNotes } from 'utils/StringFormatter';
import { MdDeleteForever } from 'react-icons/md';
import Card from 'components/card/Card';
import ReactQuillEditor from 'components/reactQuillEditor/ReactQuillEditor';
import { StatusEnum } from 'store/models/status';
import accommodationStore from 'store/dataStore/accommodationStore';
import accommodationRequestStore from 'store/dataStore/accommodationRequestStore';
import LoadingOverlay from 'components/loader/LoadingOverlay';
import { observer } from 'mobx-react';

interface IUpdateAccommodationRequestFormProps {
  //   handleUpdateRequest: (notes: string, attachments: File[]) => void;
  requestStatus: string;
  [x: string]: any;
}

const UpdateAccommodationRequestForm: React.FC<
  IUpdateAccommodationRequestFormProps
> = (props) => {
  const { id } = useParams<{ id: string; status: string }>();
  const toast = useToast();
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState('');
  const [attachments, setAttachments] = useState<File[]>([]);
  const buttonSize = useBreakpointValue({ base: 'md', lg: 'lg' }, {
    ssr: false,
  } as any);
  const cardShadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.1)',
    'unset'
  );

  const handleNotesChange = (notes: string) => {
    setNotes(notes);
    const formattedText = getFormatterStringFromNotes(notes);
    if (formattedText.trim().length > 0) {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  const handleFileUpload = (
    acceptedFiles: File[],
    rejectedFiles: FileRejection[]
  ) => {    
    if (acceptedFiles.length > 0) {
      const existingFiles: File[] = attachments;
      const newFiles: File[] = acceptedFiles.filter(
        (file: File) => !existingFiles.find((f) => f.name === file.name)
      );
      const newFilesWithUploadDate = newFiles.map((file: File) => {
        return Object.assign(file, { uploadDate: new Date().toISOString() });
      });
      setAttachments([...existingFiles, ...newFilesWithUploadDate]);
    }

    if (rejectedFiles.length > 0) {
      toast({
        title: 'Error occurred',
        description: 'Some of the files did not meet the requirements.',
        status: 'error',
        duration: 5000,
        position: 'bottom-right',
        isClosable: true,
      });
    }
  };

  const handleDeleteRow = (fileToRemove: File) => {
    const existingFiles: File[] = attachments;
    const updatedFiles = existingFiles.filter((file) => file !== fileToRemove);
    setAttachments(updatedFiles);
  };

  const columnHelper = createColumnHelper<any>();
  const columns: any = [
    columnHelper.accessor('name', {
      cell: (data) => data.getValue(),
      header: 'File Name',
    }),
    columnHelper.accessor('uploadDate', {
      cell: (data) => {
        const date = data.getValue();
        return <Moment format='MM/DD/YYYY'>{date}</Moment>;
      },
      header: 'Upload Date',
    }),
    columnHelper.display({
      id: 'actions',
      cell: (data) => (
        <IconButton
          colorScheme={'brand'}
          onClick={() => handleDeleteRow(data.row.original)}
          aria-label='Delete Attachment'
          icon={<Icon as={MdDeleteForever} />}
        />
      ),
    }),
  ];

  const resetFormAndExit = () => {
    setNotes('');
    setAttachments([]);
    props.setIsUpdating(false);
  };

  const handleUpdateRequest = async () => {
    setLoading(true);
    await accommodationRequestStore.updateAccommodationRequest(id, {
      note: notes,
      attachments,
    });
    await accommodationRequestStore.getAccommodationRequest(id);
    setLoading(false);
    resetFormAndExit();
  };

  const handleSubmitRequest = async () => {
    setLoading(true);
    await accommodationRequestStore.updateAccommodationRequest(id, {
      note: notes,
      attachments,
    });
    await accommodationRequestStore.updateStatus(
      id,
      StatusEnum.SUBMITTED
    );
    await accommodationRequestStore.getAccommodationRequest(id);
    setLoading(false);
    resetFormAndExit();
  };

  return (
    <Card key={'updating' + id} mb={5} variant={'brand'} boxShadow={cardShadow}>
      <LoadingOverlay isLoading={loading}>
        <Box px={{ base: '0', lg: '10%' }}>
          <Flex justifyContent={'end'}>
            <CloseButton onClick={() => props.setIsUpdating(false)} />
          </Flex>
          <Flex mt={'20px'}>
            <ReactQuillEditor value={notes} handleChange={handleNotesChange} />
          </Flex>
          <Text
            pt={'15px'}
            align={'left'}
            px={{ base: '0' }}
            fontSize={{ base: 'xl', lg: '2xl' }}
            fontWeight='bold'
          >
            Please upload any supporting documents:
          </Text>
          <SimpleGrid
            columns={[1, 1, 1, 2]}
            pt={'15px'}
            pb={'50px'}
            spacing={5}
          >
            <Upload handleFileUpload={handleFileUpload} />
            <Box
              border={'1px solid brand'}
              borderRadius={'10px'}
              maxHeight={'200px'}
              overflowY={'auto'}
            >
              {attachments.length > 0 ? (
                <TableWithRemoveAction columns={columns} data={attachments} />
              ) : (
                ''
              )}
            </Box>
          </SimpleGrid>
          <HStack justifyContent={'center'}>
            {props.requestStatus === StatusEnum.DRAFT ? (
              <>
                <Button
                  disabled={!(valid && notes.length > 0)}
                  onClick={() => handleUpdateRequest()}
                  size={buttonSize}
                  colorScheme='brand'
                  variant={'outline'}
                >
                  Save and Exit
                </Button>
                <Button
                  disabled={!(valid && notes.length > 0)}
                  onClick={() => handleSubmitRequest()}
                  size={buttonSize}
                  colorScheme='brand'
                >
                  Submit Request
                </Button>
              </>
            ) : (
              <Button
                disabled={!(valid && notes.length > 0)}
                onClick={() => handleUpdateRequest()}
                size={buttonSize}
                colorScheme='brand'
              >
                Submit an Update
              </Button>
            )}
          </HStack>
        </Box>
      </LoadingOverlay>
    </Card>
  );
};

export default observer(UpdateAccommodationRequestForm);
