import React from 'react';
import { Box, Button, HStack, Icon, Text, useBreakpointValue } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { MdClose, MdOutlineCheck } from 'react-icons/md';

interface IDisabilityImpactPromptProps {
    [x: string]: any;
}

const DisabilityImpactPrompt: React.FC<IDisabilityImpactPromptProps> = (props) => {

    const [disabilityImpact, setDisabilityImpact] = React.useState(null);

    const buttonSize = useBreakpointValue({ base: 'md', lg: 'lg' }, { ssr: false } as any);

    const handleDisabilityImpactValueChange = (value: string) => {
        setDisabilityImpact(value);
        props.onDisabilityImpactChange(value);
    }

    return (
        <>
            <Box>
                <Text align={'center'} fontSize={{ base: 'xl', lg: '2xl' }} fontWeight='bold'>
                    Do you have a disability that has a functional impact on your work?
                </Text>
                <HStack my={5} justifyContent={'center'}>
                    <Button onClick={() => handleDisabilityImpactValueChange('yes')}
                        size={buttonSize}
                        colorScheme='brand'
                        leftIcon={<Icon as={MdOutlineCheck} />}
                        variant={disabilityImpact === 'yes' ? 'brand' : 'outline'}
                    >
                        Yes
                    </Button>
                    <Button onClick={() => handleDisabilityImpactValueChange('no')}
                        size={buttonSize}
                        colorScheme='brand'
                        leftIcon={<Icon as={MdClose} />}
                        variant={disabilityImpact === 'no' ? 'brand' : 'outline'}
                    >
                        No
                    </Button>
                </HStack>
            </Box>
        </>
    );
}

export default observer(DisabilityImpactPrompt);
