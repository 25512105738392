import {
  Button,
  Flex,
  Icon,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { MdLibraryAdd, MdQuiz } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import Card from 'components/card/Card';
import accommodationStore from 'store/dataStore/accommodationStore';

interface IAccommodationRequestCardProps {
  [x: string]: any;
}

const AccommodationRequestCard: React.FC<IAccommodationRequestCardProps> = (
  props
) => {
  const history = useHistory();
  const { content, ...rest } = props;

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.600';
  const buttonSize = useBreakpointValue({ base: 'md', lg: 'lg' }, {
    ssr: false,
  } as any);

  const handleMakeARequestButtonClick = () => {
    accommodationStore.setNewRequestData({ isKnown: true });
    history.push('/employee/accommodation-requests/create');
  };

  const handleHelpMeChooseButtonClick = () => {
    history.push('/employee/accommodations/quiz');
  };

  return (
    <Card {...rest} alignItems='center' p='20px'>
      <Flex h='100%' direction={{ base: 'column', '2xl': 'row' }}>
        <Flex direction='column'>
          <Text
            color={textColorPrimary}
            fontWeight='bold'
            textAlign='center'
            fontSize={['md', '2xl', '2xl', '3xl']}
            mt={{ base: '20px', '2xl': '20px' }}>
            {content.title}
          </Text>
          
          <Text
            color={textColorSecondary}
            fontSize={['md', 'lg', 'xl', 'xl']}
            my={{ base: 'auto', '2xl': '10px' }}
            mx='auto'
            textAlign='center'>
            If you know the accommodation you need, please select the Create a
            Ticket button.
            <br />
            <br />
            If you are uncertain about the specific accommodation you require,
            select the "Accommodation Finder" button, which will present a
            series of questions to understand your workplace requirements
            better, then recommend suitable accommodations that align with your
            needs
          </Text>
          <Flex w='100%' justify={'center'} my={{ base: '40px' }}>
            <Stack
              direction={['column', 'column', 'column', 'row']}
              spacing={4}>
              <Button
                onClick={() => handleMakeARequestButtonClick()}
                size={buttonSize}
                colorScheme='brand'
                variant='outline'
                leftIcon={
                  <Icon boxSize={6} color='inherit' as={MdLibraryAdd} />
                }>
                Create a Ticket
              </Button>

              <Button
                onClick={() => handleHelpMeChooseButtonClick()}
                size={buttonSize}
                variant='brand'
                leftIcon={<Icon boxSize={6} as={MdQuiz} />}>
                Accommodation Finder
              </Button>
            </Stack>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default AccommodationRequestCard;
