import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Alert, AlertIcon, Box } from '@chakra-ui/react';
import { toJS } from 'mobx';

import LoadingOverlay from 'components/loader/LoadingOverlay';
import accommodationStore from 'store/dataStore/accommodationStore';
import Questions from './Questions';

interface IDisabilityQuestionnaireProps {
    selectedData: {
        disabilityId?: string,
        presentingIssueId?: string
    };
    onSubmit: (data: any) => void;
}

const QuestionsContainer: React.FC<IDisabilityQuestionnaireProps> = (props) => {
    const [questions, setQuestions] = useState([]);
    const { disabilityId, presentingIssueId } = props.selectedData;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setQuestions([]);
        setLoading(true);
        if (disabilityId) {
            accommodationStore.getDisabilityQuestions(disabilityId).then(questions => {
                setQuestions(toJS(questions));
                setLoading(false);
            });
        }

        if (presentingIssueId) {
            accommodationStore.getPresentingIssueQuestions(presentingIssueId).then(questions => {
                setQuestions(toJS(questions));
                setLoading(false);
            });
        }
    }, [disabilityId, presentingIssueId]);

    const handleFormSubmit = (data: any) => {
        props.onSubmit(data);
    };

    return (
        <LoadingOverlay isLoading={loading}>
            <Box>
                <Box px={{ base: '0', lg: '15%' }}>
                    {questions.length ? <Questions questions={questions} onSubmit={handleFormSubmit} /> :
                        <Alert status='info' colorScheme={'brand'}>
                            <AlertIcon />
                            We are working on adding more questions for this category. Please check back later or select different category.
                        </Alert>
                    }
                </Box>
            </Box>
        </LoadingOverlay>
    );
}

export default observer(QuestionsContainer);
