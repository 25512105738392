import {
    Alert,
    AlertIcon,
    Box,
    Flex,
    Image,
    ListItem,
    Text, UnorderedList,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import accommodationStore from '../../../../store/dataStore/accommodationStore';

import Card from 'components/card/Card';
import { InfoIcon } from '@chakra-ui/icons';

const MagnifyScanAnimation = require('assets/animations/MagnifyScan.gif');

interface IAIAccommodationSuggestionsProps {
    [x: string]: any;
}

const AIAccommodationSuggestions: React.FC<IAIAccommodationSuggestionsProps> = (props) => {
    const [processingDocuments, setProcessingDocuments] = useState(false);
    const [recommendations, setRecommendations] = useState([]);

    useEffect(() => {
        setProcessingDocuments(true);
        accommodationStore.getPrediction().then((response) => {
            if (response.error) {
                accommodationStore.onError(response.error);
            }
            setRecommendations(response.data);
        }).finally(() => {
            setProcessingDocuments(false);
            props.setIsValid(true);
        });
    }, []);

    const renderResults = () => {
        return (
            <>
                <Text
                    px={ { base: '0', lg: '10%' } }
                    fontSize={ { base: 'xl', lg: '2xl' } }
                    fontWeight='bold'
                    textAlign={ 'center' }>
                    Results after analyzing documents
                </Text>

                <Card
                    bg={ 'brand.100' }
                    textAlign={ 'center' }
                    fontStyle={ 'italic' }
                    fontSize={ 'lg' }
                    my={2}
                >
                    <Box>
                        <InfoIcon mr={ 2 }/>
                        Please read over the extracted data from your files and choose the most suitable option in the next step.
                    </Box>
                </Card>


                <UnorderedList
                    my={ 3 }
                >
                    { recommendations.map((recommendation) => {
                        return (
                            <ListItem>
                                { recommendation }
                            </ListItem>
                        );
                    }) }
                </UnorderedList>
                {
                    !recommendations.length && (
                        <Alert status='warning'
                            my={ 2 }>
                            <AlertIcon/>
                            We couldn't find anything to display while analyzing the documents. Please continue to the next step by clicking 'Next' button.
                        </Alert>
                    )
                }

            </>
        );
    };

    return (
        <Box px={ { base: '5%', xl: '20%' } }>
            { processingDocuments ? (
                <Flex
                    direction={ 'column' }
                    justifyContent={ 'center' }
                    alignItems={ 'center' }>
                    <Text
                        px={ { base: '0', lg: '15%' } }
                        fontSize={ { base: 'xl', lg: '2xl' } }
                        fontWeight='bold'
                        textAlign={ 'center' }>
                        Analyzing documents and generating suggestions...
                    </Text>
                    <Image
                        height={ '200px' }
                        alt='analyzing documents'
                        src={ MagnifyScanAnimation }
                    />
                </Flex>
            ) : (
                renderResults()
            ) }
        </Box>
    );
};

export default observer(AIAccommodationSuggestions);
