import React, { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { observer } from 'mobx-react-lite';
import StepWizard from 'react-step-wizard';

import Categories from './newRequestComponents/Categories';
import Accommodations from './Accommodations';
import FormWizardItemContainer from '../components/formWizard/FormWizardItemContainer';
import accommodationStore from 'store/dataStore/accommodationStore';
import Confirmation from './newRequestComponents/Confirmation';
import CreateAccommodationRequest from './newRequestComponents/CreateAccommodationRequest';
import Documentation from './newRequestComponents/Documentation';
import AIAccommodationSuggestions from './newRequestComponents/AIAccommodationSuggestions';

function NewAccommodationRequestContainer() {
  const history = useHistory();
  const [hasUserEnteredData, setHasUserEnteredData] = useState(false);
  /* eslint-disable */
  useEffect(() => {
    setHasUserEnteredData(true);
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue =
        'You have unsaved changes, are you sure you want to leave?';
    };
    if (hasUserEnteredData) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    if (accommodationStore.requestCompleteStatus) {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      setHasUserEnteredData(false);
    };
  }, [
    history.location.pathname,
    hasUserEnteredData,
    accommodationStore.requestCompleteStatus,
  ]);

  const getJourneyWithKnownAccommodation = (): any => {
    return [
      <FormWizardItemContainer stepName={'documentation'} key={'documentation'}>
        <Documentation />
      </FormWizardItemContainer>,
      accommodationStore.newRequest.attachments.length > 0 && (
        <FormWizardItemContainer
          stepName={'analyzeDocumentation'}
          key={'analyzeDocumentation'}
        >
          <AIAccommodationSuggestions />
        </FormWizardItemContainer>
      ),
      <FormWizardItemContainer stepName={'categories'} key={'Categories'}>
        <Categories />
      </FormWizardItemContainer>,
      <FormWizardItemContainer
        stepName={'accommodationsList'}
        key={'Accommodations'}
      >
        <Accommodations />
      </FormWizardItemContainer>,
      <FormWizardItemContainer
        stepName={'submit'}
        key={'CreateAccommodationRequest'}
      >
        <CreateAccommodationRequest />
      </FormWizardItemContainer>,
    ];
  };

  return (
    <Box pt={{ base: '180px', md: '150px', xl: '100px' }}>
      <Prompt
        when={hasUserEnteredData && !accommodationStore.requestCompleteStatus}
        message='You have unsaved changes, are you sure you want to leave?'
      />
      <Card>
        {accommodationStore.requestCompleteStatus === true ? (
          <Confirmation />
        ) : (
          <StepWizard isLazyMount={true} className='formStepWizard'>
            {getJourneyWithKnownAccommodation()}
          </StepWizard>
        )}
      </Card>
    </Box>
  );
}

export default observer(NewAccommodationRequestContainer);
