import { AxiosError } from 'axios';

const getErrorFromStatusCode = (err: AxiosError | any) => {
    let errorMessage = '';
    if (err instanceof AxiosError && err?.response) {
        if (err.response.data?.message) {
            errorMessage = err.response.data?.message;
        }
        else {
            switch (err.response.status) {
                case 400:
                    errorMessage = ('Wrong request.');
                    break;
                case 401:
                    errorMessage = 'Unauthorized Access.';
                    break;
                case 404:
                    errorMessage = 'Resource not found.';
                    break;
                default:
                    errorMessage = 'Unknown error occurred.';
            }
        }
    }
    return errorMessage;
}

export default getErrorFromStatusCode;
