import React from 'react';
import {
  Badge,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { MdNotificationsNone, MdNotificationsActive, MdUpgrade } from 'react-icons/md';
import { Notification } from 'store/models/notification';

import notificationStore from 'store/dataStore/notificationStore';
import LoadingOverlay from 'components/loader/LoadingOverlay';
import { NotificationType, NotificationTypeEnum } from './Notification/NotificationType';

const Notifications: React.FC = () => {
  // Theme constants
  let menuBg = useColorModeValue('white', 'navy.800');
  const navbarIconActive = useColorModeValue('brand.500', 'brand.400');
  const navbarIcon = useColorModeValue('gray.400', 'white');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.700', 'brand.400');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );

  const renderNotifications = () => {
      const setBackground = (notification: Notification) => {
          return !notification.read_at ? 'brand.100' : '';
      };
      return notificationStore.notifications?.length
        ? notificationStore.notifications.map((notification: Notification) => {
            const Component = NotificationType[notification.data.type as NotificationTypeEnum].component;
              return (
                      <MenuItem
                          _hover={ { bg: 'brand.200' } }
                          key={ toJS(notification).id }
                          backgroundColor={setBackground(notification)}
                          outlineColor={ 'brand.200' }
                          px="0"
                          borderRadius="8px"
                          mb="10px"
                      >
                          <Component notification={ toJS(notification) }/>
                      </MenuItem>
              );
            }
        )
        : <span>No New Notifications</span>;
  };

  return (
      <Menu>
          <MenuButton p="0px">
            <Icon
              aria-label="Notifications icon"
              mt="6px"
              as={
                notificationStore.unreadCount ? MdNotificationsActive : MdNotificationsNone
              }
              color={notificationStore.unreadCount ? navbarIconActive : navbarIcon}
              h="25px"
              w="25px"
            />
          </MenuButton>
          {notificationStore.unreadCount > 0 && (
            <Badge transform={'translate(-10px, -10px)'} className="notificationBadge" color="white" bg="brand.500" borderRadius="50%">
              {notificationStore.unreadCount}
            </Badge>
          )}
        <MenuList
            boxShadow={shadow}
            p="20px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
            mt="22px"
            me={{ base: '30px', md: 'unset' }}
            minW={{ base: 'unset', md: '400px', xl: '450px' }}
            maxW={{ base: '360px', md: 'unset' }}
        >
          <Flex w="100%">
            <Text fontSize="md" fontWeight="600" color={textColor}>
              Notifications
            </Text>
            {notificationStore.unreadCount > 0 && (
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textColorBrand}
                ms="auto"
                cursor="pointer"
                onClick={notificationStore.markNotificationsAsRead}
              >
                Mark all read
              </Text>
            )}
          </Flex>
          <LoadingOverlay isLoading={notificationStore.loading}>
            <Flex
              flexDirection="column"
              gap={2}
              maxHeight={'500px'}
              overflow="scroll"
              p={5}
            >
              {renderNotifications()}
            </Flex>
          </LoadingOverlay>
        </MenuList>
      </Menu>
  );
}

export default observer(Notifications);
