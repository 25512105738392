import Moment from 'react-moment';
import { Box, Flex, Icon, IconButton, Text, useColorModeValue } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { createColumnHelper } from '@tanstack/react-table';
import { FileRejection } from 'react-dropzone';

import LoadingOverlay from 'components/loader/LoadingOverlay';
import accommodationStore from 'store/dataStore/accommodationStore';
import Upload from 'components/upload/Upload';
import { displayErrorToast } from 'utils/toastHelper';
import CustomSimpleTable from 'components/tables/CustomSimpleTable';
import React, { useEffect } from 'react';
import { DeleteIcon } from '@chakra-ui/icons';

interface IDocumentationProps {
  [x: string]: any;
}

const Documentation: React.FC<IDocumentationProps> = (props) => {
  useEffect(() => {
    props.setIsValid(true);
  }, []);

  const columnHelper = createColumnHelper<any>();

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const attachmentsColumns: any = [
    columnHelper.accessor('name', {
      cell: (data) => {
        return (
            <Text
                color={textColor}
                fontSize={{ base: 'md', md: 'lg' }}
                fontWeight='500'
            >
              {data.getValue()}
            </Text>
        );
      },
      header: 'File Name',
    }),
    columnHelper.accessor('uploadDate', {
      cell: (data) => {
        const date = data.getValue();
        return <Moment format='MMM Do, YYYY'>{date}</Moment>;
      },
      header: 'Upload Date',
    }),
    columnHelper.display({
      id: 'actions',
      cell: (data) => (
          <IconButton
              aria-label={ 'Delete Document' }
              variant='outline'
              colorScheme='red'
              borderRadius='10px'
              fontSize={ 20 }
              onClick={ () => handleDeleteRow(data.row.original) }
              icon={ <DeleteIcon/> }
          />
      ),
    }),
  ];

  const handleFileUpload = (
    acceptedFiles: File[],
    rejectedFiles: FileRejection[]
  ) => {
    if (rejectedFiles.length > 0) {
      displayErrorToast('Some of the files did not meet the requirements.');
    }

    if (acceptedFiles.length > 0) {
      const existingFiles: File[] = accommodationStore.newRequest.attachments;

      const newFiles: File[] = acceptedFiles.filter(
        (file: File) => !existingFiles.find((f) => f.name === file.name)
      );
      const newFilesWithUploadDate = newFiles.map((file: File) => {
        return Object.assign(file, { uploadDate: new Date().toISOString() });
      });
      accommodationStore.setNewRequestData({
        attachments: [...existingFiles, ...newFilesWithUploadDate],
      });
    }
  };

  const handleDeleteRow = (fileToRemove: File) => {
    const updatedFiles = accommodationStore.newRequest.attachments.filter(
      (file) => file !== fileToRemove
    );
    accommodationStore.setNewRequestData({ attachments: updatedFiles });
  };

  return (
    <LoadingOverlay isLoading={accommodationStore.loading}>
      <Box px={{ base: '5%', xl: '20%' }}>
        <Text px={{ base: '0', lg: '15%' }} fontSize={{ base: 'xl', lg: '2xl' }} textAlign={'center'} fontWeight='bold'>
          1. Upload any supporting documents
        </Text>
        <Flex mt={'20px'} direction='column' gap={5}>
          <Upload handleFileUpload={handleFileUpload} />
          {accommodationStore.newRequest?.attachments?.length > 0 && (
            <CustomSimpleTable
              columns={attachmentsColumns}
              data={accommodationStore.newRequest.attachments}
            />
          )}
        </Flex>
      </Box>
    </LoadingOverlay>
  );
};

export default observer(Documentation);
