import {
  Flex,
  Text,
  useColorModeValue,
  Heading,
} from "@chakra-ui/react";
import { MdOutlineCheckCircle } from "react-icons/md";
import { NavLink } from "react-router-dom";

function PasswordResetCompleteScreen() {
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const textColorSecondary = 'gray.500';

  return (
    <Flex
      maxW={{ base: '100%', md: 'max-content' }}
      w='100%'
      mx={{ base: 'auto', lg: '0px' }}
      me='auto'
      h='100%'
      alignItems='center'
      justifyContent='center'
      mb={{ base: '30px', md: '60px' }}
      px={{ base: '25px', md: '0px' }}
      mt={{ base: '40px', md: '14vh' }}
      flexDirection='column'>
      <Heading color={textColor} fontSize='36px' mb='10px' display='flex' alignItems='center'>
        Password Successful!
        <MdOutlineCheckCircle color='teal' />
      </Heading>
      <Flex
        zIndex='2'
        direction='column'
        w={{ base: '100%', md: '620px' }}
        maxW='100%'
        background='transparent'
        borderRadius='15px'
        mx={{ base: 'auto', lg: 'unset' }}
        me='auto'
        mb={{ base: '20px', md: 'auto' }}>
        <Text
          color={textColorSecondary}
          fontSize='xl'
          my={{ base: 'auto', '2xl': '10px' }}
          mx='auto'
          textAlign='center'>
            Use the link below to login with new password.
        </Text>
      </Flex>
      <NavLink to='/auth/login'>
        <Text
          color={textColorBrand}
          fontSize='lg'
          // w='124px'
          fontWeight='500'>
          &lt; Back to sign in
        </Text>
      </NavLink>
    </Flex>
  );
}

export default PasswordResetCompleteScreen;
