import { Icon } from '@chakra-ui/react';
import { MdHome, MdOutlineHistory, MdViewList } from 'react-icons/md';
// Employee Imports
import EmployeeDashboard from 'views/employee/default';

// Auth Imports
import LogInCentered from 'views/auth/login';
import RegisterCentered from 'views/auth/register';
import ForgotPassword from 'views/auth/forgotPassword';
import ResetPassword from 'views/auth/resetPassword';
import ListAccommodationRequests from 'views/employee/accommodationRequests/ListAccommodationRequests';
import NewAccommodationRequestContainer from 'views/employee/newAccommodationRequest/NewAccommodationRequestContainer';
import ViewAccommodationRequest from 'views/employee/accommodationRequests/ViewAccommodationRequest';
import QuizContainer from '../views/employee/QuizContainer';
import Settings from 'views/employee/Settings';
import Profile from 'views/employee/Profile';
import Logout from 'views/auth/logout';
import { ViewIcon } from '@chakra-ui/icons';

const routes = [
	{
		name: 'Dashboard',
		layout: '/employee',
		path: '/dashboard',
		icon: <Icon as={MdHome} width='25px' height='25px' color='inherit' />,
		component: EmployeeDashboard
	},
	{
		name: 'Find Your Accommodation',
		layout: '/employee',
		path: '/accommodations/quiz',
		icon: <Icon as={MdHome} width='25px' height='25px' color='inherit' />,
		component: QuizContainer
	},
	{
		name: 'Create Accommodation Request',
		layout: '/employee',
		path: '/accommodation-requests/create',
		icon: <Icon as={MdHome} width='25px' height='25px' color='inherit' />,
		component: NewAccommodationRequestContainer
	},
	{
		name: 'Accommodation Tickets',
		layout: '/employee',
		path: '/accommodation-requests',
		icon: <Icon as={MdViewList} width='25px' height='25px' color='inherit' />,
		component: ListAccommodationRequests
	},
	{
		name: 'Accommodation Request Details',
		layout: '/employee',
		path: '/view/requests/:id',
		icon: <Icon as={ViewIcon} width='25px' height='25px' color='inherit' />,
		component: ViewAccommodationRequest
	},
	{
		name: 'Settings',
		layout: '/employee',
		path: '/settings',
		icon: <Icon as={MdOutlineHistory} width='25px' height='25px' color='inherit' />,
		showInSidebar: false,
		component: Settings
	},
	{
		name: 'Profile',
		layout: '/employee',
		path: '/profile',
		icon: <Icon as={MdOutlineHistory} width='25px' height='25px' color='inherit' />,
		showInSidebar: false,
		component: Profile
	},
	{
		name: 'Login',
		layout: '/auth',
		path: '/login',
		component: LogInCentered
	},
	{
		name: 'Logout',
		layout: '/auth',
		path: '/logout',
		component: Logout
	},
	{
		name: 'Register',
		layout: '/auth',
		path: '/register',
		component: RegisterCentered
	},
	{
		name: 'Forgot Password',
		layout: '/auth',
		path: '/forgot-password',
		component: ForgotPassword
	},
	{
		name: 'Reset Password',
		layout: '/auth',
		path: '/reset-password',
		component: ResetPassword
	},
];

export default routes;
