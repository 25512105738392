import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import userStore from 'store/dataStore/userStore';
import * as Constants from '../../../utils/Constants';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Box } from '@chakra-ui/react';
import LoadingOverlay from '../../../components/loader/LoadingOverlay';

const Logout = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_USER_LOGGED_IN_KEY))) {
      history.push('/auth/login');
      return;
    }

    setIsLoading(true);
    userStore.logout().then(() => {
      localStorage.removeItem(Constants.LOCAL_STORAGE_USER_DATA_KEY);
      localStorage.removeItem(Constants.LOCAL_STORAGE_USER_LOGGED_IN_KEY);
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('access_token');
      localStorage.removeItem('token_expiry_time');
      userStore.user = null;

      Cookies.remove(Constants.XSRF_TOKEN_KEY);

      history.push('/auth/login');
    }).catch((error) => {
      userStore.setErrors({status: error.status, message: error.data.message});
    }).finally(() => {setIsLoading(false)})
  }, [])

  //TODO::CREATE AUTH STORE, MANAGE GLOBAL LOADER FROM THERE JUST LIKE EMPLOYEE LAYOUT
  return (
      <Box position={'absolute'} top={0} left={0}>
        <LoadingOverlay isLoading={isLoading} isGlobal={true} message={"Logging out"}>
        </LoadingOverlay>
      </Box>
  );
}

export default observer(Logout);
