import { Spinner, Box, useColorModeValue, Text } from "@chakra-ui/react";
import React from 'react';
import Card from '../card/Card';

interface LoadingOverlayProps {
    children?: React.ReactNode;
    isLoading: boolean;
    isGlobal?: boolean;
    message?: string
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = (props) => {
    const bgColor = useColorModeValue('white', 'navy.700');

    let conditionalStyles = {};

    if (props.isGlobal) {
        conditionalStyles = {
            minHeight: '100vh',
            minWidth: '100vw',
            zIndex: '100'
        };
    }

    return (
        <Box position="relative">
            {props.children}
            {props.isLoading && (
                <Card
                    position="absolute"
                    top={-1}
                    left={0}
                    right={0}
                    bottom={-1}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor={bgColor}
                    style={ conditionalStyles }
                >
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='brand.500'
                        size="xl" />
                    {
                        props.message && (
                            <Text fontSize={'lg'} fontWeight={'bold'} my={2} textAlign={'center'}>
                                { props.message }
                            </Text>
                        )
                    }
                </Card>
            )}
        </Box>
    );
};

export default LoadingOverlay;

