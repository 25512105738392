import React, { useEffect, useState } from "react";
import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Flex,
    HStack,
    Icon,
    Text,
    useBreakpointValue,
} from "@chakra-ui/react";
import accommodationStore from "store/dataStore/accommodationStore";
import { MdClose, MdOutlineCheck } from "react-icons/md";
import IntervalSubQuestions from "./IntervalSubQuestions";
import LoadingOverlay from "components/loader/LoadingOverlay";

interface ISubQuestionProps {
    questions: any;
    [x: string]: any;
}

const SubQuestions: React.FC<ISubQuestionProps> = (props) => {
    const buttonSize = useBreakpointValue({ base: "md", lg: "lg" }, {
        ssr: false,
    } as any);


    const { questions } = props;

    const [answers, setAnswers] = useState({} as any);

    const [allowNextStep, setAllowNextStep] = useState(false);
    const [intervalSubQuestions, setIntervalSubQuestions] = useState([]);

    const [loading, setLoading] = useState(false);
    const [displayAlert, setDisplayAlert] = useState(false);

    useEffect(() => {
        setIntervalSubQuestions([]);

        const ansObjectKeys = Object.keys(answers);

        if (ansObjectKeys.length === questions.length && ansObjectKeys.length > 0) {
            setAllowNextStep(true);
        }

    }, [answers, questions.length]);

    const handleQuestionResponse = (questionId: string, response: boolean) => {
        setDisplayAlert(false);
        setAnswers({
            ...answers,
            [questionId]: response,
        });
    };

    const handleNextStep = () => {
        setLoading(true);
        const ansObjectKeys = Object.keys(answers);
        const fetchIntervalQuestions = async () => {
            let intervalSubQuestions: any[] = [];
            for (const key of ansObjectKeys) {
                if (answers[key]) {
                    const newQuestions = await accommodationStore.getQuestionsWithSubQuestions(key);
                    intervalSubQuestions.push(...newQuestions);
                }
            }
            setIntervalSubQuestions(intervalSubQuestions);
        };
        fetchIntervalQuestions().then(() => {
            setLoading(false);
            setDisplayAlert(true);
        });
    }

    const handleFormSubmit = (intervalAnswers: any) => {
        props.onSubmit({
            subQuestionResponses: answers,
            intervalResponses: intervalAnswers
        });
    };

    return (
        <>
            <Flex direction={'column'} justifyContent={'center'} alignItems={'center'}>
                {questions.map((question: any, index: any) => {
                    const id = question.id;
                    let answer;
                    if (answers.hasOwnProperty(id)) {
                        answer = answers[id];
                    }

                    return (
                        <Box py={10} key={id}>
                            <Text
                                align={"center"}
                                fontSize={{ base: "xl", lg: "2xl" }}
                                fontWeight="bold"
                            >
                                {question.question_text}
                            </Text>
                            <HStack pt={5} justifyContent={"center"}>
                                <Button
                                    onClick={() => handleQuestionResponse(question.id, true)}
                                    size={buttonSize}
                                    colorScheme="brand"
                                    leftIcon={<Icon as={MdOutlineCheck} />}
                                    variant={answer === true ? "brand" : "outline"}
                                >
                                    Yes
                                </Button>
                                <Button
                                    onClick={() => handleQuestionResponse(question.id, false)}
                                    size={buttonSize}
                                    colorScheme="brand"
                                    leftIcon={<Icon as={MdClose} />}
                                    variant={answer === false ? "brand" : "outline"}
                                >
                                    No
                                </Button>
                            </HStack>
                        </Box>
                    );
                })}
                <Button
                    onClick={() => handleNextStep()}
                    disabled={!allowNextStep}
                    size={buttonSize}
                    colorScheme="brand"
                    variant={"link"}
                >
                    {`Next >`}
                </Button>
            </Flex>

            <LoadingOverlay isLoading={loading}>
                <Box mt={2} minH={'200px'}>
                    {intervalSubQuestions.length ? <IntervalSubQuestions questions={intervalSubQuestions} onSubmit={handleFormSubmit}/> :
                        !displayAlert ? '' :
                        <Alert status='info' colorScheme={'brand'}>
                            <AlertIcon />
                            Seems like none of the questions above are applicable to you, please select a different choice from options above.
                        </Alert>
                    }
                </Box>
            </LoadingOverlay>
        </>
    );
};

export default SubQuestions;
