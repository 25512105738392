import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import FormWizardPagination from './FormWizardPagination';

type FormWizardItemContainerProps = {
    [x: string]: any;
};
const FormWizardItemContainer: React.FC<FormWizardItemContainerProps> = (props) => {
    const [isValid, setIsValid] = useState(false);

    return <Box>
        {/* {props.children} */}
        {React.cloneElement(props.children, {
            setIsValid: setIsValid,
            isValid: isValid,
            ...props
        })}
        <FormWizardPagination isValidated={isValid} {...props} />
    </Box>
}
export default FormWizardItemContainer;
