import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from '@chakra-ui/react';
import Card from '../../components/card/Card';
import DisabilityImpactPrompt from './newAccommodationRequest/quizComponents/DisabilityImpactPrompt';
import Disabilities from './newAccommodationRequest/quizComponents/Disabilities';
import PresentingIssues from './newAccommodationRequest/quizComponents/PresentingIssues';
import QuestionsContainer from './newAccommodationRequest/quizComponents/QuestionsContainer';
import RecommendedAccommodations from './newAccommodationRequest/quizComponents/RecommendedAccommodations';
import accommodationStore from 'store/dataStore/accommodationStore';

const QuizContainer = () => {
    const [hasDisabilityImpact, setHasDisabilityImpact] = useState(null);
    const [selectedData, setSelectedData] = useState({});
    const [recommendedAccommodations, setRecommendedAccommodations] = useState([]);
    const [displayResult, setDisplayResult] = useState(false);

    useEffect(() => {
        setSelectedData({});
    }, [hasDisabilityImpact]);

    const handleFormSubmit = (data: any) => {
        let recommendedAccommodations: any[] = [];
        const fetchRecommendations = async () => {
            const response = await accommodationStore.getRecommendedAccommodations(data);
            recommendedAccommodations = response;
        }

        fetchRecommendations().then(() => {
            setRecommendedAccommodations(recommendedAccommodations);
            setDisplayResult(true);
        });
    }

    const handleRestart = () => {
        setSelectedData({});
        setHasDisabilityImpact(null);
        setDisplayResult(false);
    }

    return (
        <Box pt={{ base: '180px', md: '150px', xl: '100px' }}>
            {displayResult ?
                <Card>
                    <RecommendedAccommodations recommendedAccommodations={recommendedAccommodations} handleRestart={handleRestart} />
                </Card>
                :
                <Card minH={'calc(100vh - 160px);'}>
                    <DisabilityImpactPrompt onDisabilityImpactChange={setHasDisabilityImpact} />
                    {
                        hasDisabilityImpact === 'yes' ?
                            <Disabilities onDisabilitySelect={setSelectedData} /> :
                            hasDisabilityImpact === 'no' ?
                                <PresentingIssues onPresentingIssueSelect={setSelectedData} /> :
                                ''
                    }
                    {
                        selectedData.hasOwnProperty('disabilityId') || selectedData.hasOwnProperty('presentingIssueId')
                            ? <QuestionsContainer selectedData={selectedData} onSubmit={handleFormSubmit}/> : ''
                    }
                </Card>
            }
        </Box>
    );
}

export default observer(QuizContainer);
