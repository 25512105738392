import { Button, Flex, Spacer, useBreakpointValue } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';

type FormWizardPaginationProps = {
  isValidated: boolean;
  [x: string]: any;
};

const FormWizardPagination: React.FC<FormWizardPaginationProps> = (props) => {
  const buttonSize = useBreakpointValue({ base: 'md'}, {
    ssr: false,
  } as any);
  const handleNextStep = (): void => {
    props.nextStep();
  };

  return (
    <Flex my={5} justifyContent={'space-between'} width={{base: '100%', lg: '70%'}} mx={'auto'}>
      <Button
          onClick={() => props.previousStep()}
          size={buttonSize}
          colorScheme={'navy'}
          variant='outline'
          disabled={props.currentStep <= 1}
      >
        {'< Back'}
      </Button>
      <Button
          isDisabled={!props.isValidated || props.currentStep == props.totalSteps}
          onClick={() => handleNextStep()}
          size={buttonSize}
          colorScheme={'brand'}
      >
        {'Next >'}
      </Button>
    </Flex>
  );
};

export default observer(FormWizardPagination);
