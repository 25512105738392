import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { request } from '../store/axios';

class EchoService {
    echo: Echo | null = null;

    constructor() {
        if (!window.Pusher) {
            window.Pusher = Pusher;
        }
    }

    disconnect() {
        if (this.echo) {
            this.echo.disconnect();
            this.echo = null;
        }
    }

    createConnection() {
        if (!this.echo) {
            this.echo = new Echo({
                broadcaster: 'pusher',
                key: process.env.REACT_APP_PUSHER_APP_KEY,
                cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
                forceTLS: true,
                authorizer: (channel: any) => {
                    return {
                        authorize: (socketId: any, callback: any) => {
                            request.post('/broadcasting/auth', {
                                socket_id: socketId,
                                channel_name: channel.name
                            }).then(response => {
                                callback(null, response.data);
                            }).catch(error => {
                                callback(error);
                            });
                        }
                    };
                },
            })
        }

        return this.echo;
    }
}

export const echoService = new EchoService();
