import {
  Avatar,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';

import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import routes from 'routes/routes';
import userStore from 'store/dataStore/userStore';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import Notifications from './Notifications';

const HeaderLinks = (props: { secondary: boolean }) => {
  const history = useHistory();
  const { secondary } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  // Chakra Color Mode
  const navbarIcon = useColorModeValue('gray.400', 'white');
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );

  const handleLogOut = async () => {
    history.push('/auth/logout');
    return;
  };

  const userData = userStore.user;

  const {
    firstName = '',
    lastName = '',
    avatar = '',
  } = userData ? userData : {};

  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
    >
      <SearchBar
        placeholder="Search Accommodations..."
        mb={() => {
          if (secondary) {
            return { base: '10px', md: 'unset' };
          }
          return 'unset';
        }}
        me="10px"
        borderRadius="30px"
      />
      <SidebarResponsive routes={routes} />
      <Notifications />
      <Button
        variant="no-hover"
        bg="transparent"
        p="0px"
        minW="unset"
        minH="unset"
        h="18px"
        w="max-content"
        onClick={toggleColorMode}
      >
        <Icon
          aria-label="Notifications icon"
          me="10px"
          h="25px"
          w="25px"
          color={navbarIcon}
          as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
        />
      </Button>
      <Menu>
        <MenuButton _focus={{ boxShadow: 'outline' }} p="0px">
          <Avatar
            src={avatar}
            _hover={{ cursor: 'pointer', outline: `3px solid ${'black'}` }}
            color="white"
            name={`${firstName} ${lastName}`}
            bg="brand.500"
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋🏽&nbsp; Hey, {`${firstName} ${lastName}`}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            <Link to="/employee/profile">
              <MenuItem
                _focus={{ boxShadow: 'outline' }}
                color={textColor}
                borderRadius="8px"
                px="14px"
              >
                <Text fontSize="sm">Profile</Text>
              </MenuItem>
            </Link>
            <MenuItem
              _focus={{ boxShadow: 'outline' }}
              onClick={handleLogOut}
              color="red.400"
              borderRadius="8px"
              px="14px"
            >
              <Text fontSize="sm">Log out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
};

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};

export default observer(HeaderLinks);
