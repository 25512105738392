import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input, SimpleGrid,
    useColorModeValue, useToast,
} from '@chakra-ui/react';
import { observer } from 'mobx-react';
import Card from 'components/card/Card';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UserProfile } from 'store/models/user';
import { usePlacesWidget } from 'react-google-autocomplete';
import userStore from 'store/dataStore/userStore';
import { ChakraPhone } from 'components/ChakraPhone';
import EditableAvatar from 'components/EditableAvatar';
import InputDefaultText from 'components/formControls/InputDefaultText';
import { extractAddressFields } from 'utils/AddressFormatter';
import LoadingOverlay from 'components/loader/LoadingOverlay';
import { DownloadIcon } from '@chakra-ui/icons';
import accommodationStore from '../../store/dataStore/accommodationStore';

const Profile = () => {
    const [profile, setProfile] = useState<UserProfile>();
    const [phone, setPhone] = useState('');
    const [loadingState, setLoadingState] = useState({ isLoading: false, message: '' });
    const [address, setAddress] = useState({
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
    });
    const toast = useToast();

    const { ref } = usePlacesWidget({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        onPlaceSelected: (place) => {
            const formatterAddress = extractAddressFields(place);
            setAddress({ ...address, ...formatterAddress });
        },
        options: {
            types: ['address'],
        },
    });

    const {
        handleSubmit,
        register,
        reset,
    } = useForm<UserProfile>({ defaultValues: profile });

    useEffect(() => {
        setLoadingState({isLoading: true, message: "Fetching User's Profile"});
        userStore.getUserProfile().then((data) => {
            setProfile(data);
            reset(data);
            setAddress({
                addressLine1: data.profile.addressLine1,
                addressLine2: data.profile.addressLine2,
                city: data.profile.city,
                state: data.profile.state,
                country: data.profile.country,
                postalCode: data.profile.postalCode,
            });
        }).catch((error) => {
            showError(error.data.message);
        }).finally(() => {
            setLoadingState({isLoading: false, message: ''});
        });
    }, [reset]);

    const showError = (message: string) => {
        toast({
            title: 'Error occurred',
            description: message,
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            isClosable: true,
        });
    }

    const onSubmit: SubmitHandler<UserProfile> = async (formData) => {
        const phoneNumberForAPI = phone.replace(/\D/g, '').slice(-10);

        const profileData = {
            ...formData,
            phone: phoneNumberForAPI,
            staffId: '9999',
            ...address,
        };
        setLoadingState({isLoading: true, message: "Saving User's Profile"});
        await userStore.updateProfile(profileData);
        setLoadingState({isLoading: false, message: ""});

    };

    const handleAvatarSave = async (avatar: File) => {
        await userStore.uploadAvatar(avatar);
    };

    const downloadEmployeeProfile = () => {
        setLoadingState({isLoading: true, message: "Generating Individual Employee Profile"});
        userStore.downloadEmployeeProfile().then((data) => {
            accommodationStore.downloadAttachment(data.url);
        }).catch((error) => {
            showError(error.data.message);
        }).finally(() => {
            setLoadingState({isLoading: false, message: ""});
        });
    };

    const textColorInput = useColorModeValue('secondaryGray.900', 'white');

    return (
        <LoadingOverlay isLoading={ loadingState.isLoading }
            message={ loadingState.message }>
            <Box
                mt={ { base: '180px', md: '150px', xl: '100px' } }
            >
                <Card>
                    <SimpleGrid columns={ { md: 2 } }
                        spacing={ 5 }
                        my={ 2 }
                        alignItems={ 'center' }>
                        <Box textAlign={ 'center' }>
                            <EditableAvatar
                                src={ userStore.user && userStore.user.avatar }
                                handleAvatarSave={ handleAvatarSave }
                            />
                            <Button
                                size={ 'sm' }
                                variant={ 'outline' }
                                colorScheme={ 'navy' }
                                onClick={ downloadEmployeeProfile }
                            >
                                <DownloadIcon/> Employee Profile
                            </Button>
                        </Box>
                        <SimpleGrid columns={ { md: 1 } }
                            spacing={ 5 }>
                            <FormControl>
                                <FormLabel>First Name</FormLabel>
                                <InputDefaultText
                                    placeholder="First Name"
                                    id="firstName"
                                    name="firstName"
                                    register={ register }
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Last Name</FormLabel>
                                <InputDefaultText
                                    placeholder="Last Name"
                                    id="lastName"
                                    name="lastName"
                                    register={ register }
                                />
                            </FormControl>
                        </SimpleGrid>
                    </SimpleGrid>

                    <SimpleGrid columns={ { md: 1 } }
                        spacing={ 5 }
                        my={ 2 }>
                        <FormControl>
                            <FormLabel>Email</FormLabel>
                            <InputDefaultText
                                placeholder="mail@example.com"
                                id="email"
                                name="email"
                                register={ register }
                            />
                        </FormControl>
                    </SimpleGrid>

                    <SimpleGrid columns={ { md: 2, lg: 3 } }
                        spacing={ 5 }
                        my={ 2 }>
                        <FormControl>
                            <FormLabel>Date Of Birth</FormLabel>
                            <Input
                                type="date"
                                id="dateOfBirth"
                                name="dateOfBirth"
                                colorScheme="brand"
                                focusBorderColor="#207077"
                                { ...register('dateOfBirth') }
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Pronouns</FormLabel>
                            <InputDefaultText
                                placeholder="Prefered pronouns here"
                                id="pronouns"
                                name="pronouns"
                                register={ register }
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Staff Id</FormLabel>
                            <InputDefaultText
                                placeholder="# staffId"
                                id="staffId"
                                name="staffId"
                                register={ register }
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Phone Number</FormLabel>
                            <ChakraPhone
                                { ...register('phone') }
                                value={ phone }
                                onChange={ setPhone }
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>ODSP Status</FormLabel>
                            <InputDefaultText
                                placeholder="Enter ODSP Status here"
                                id="ODSPStatus"
                                name="ODSPStatus"
                                register={ register }
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>WSIB Status</FormLabel>
                            <InputDefaultText
                                placeholder="Enter WSIB Status here"
                                id="WSIBStatus"
                                name="WSIBStatus"
                                register={ register }
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Street Address</FormLabel>
                            <Input
                                id="address1"
                                placeholder="Start typing your address"
                                name="address1"
                                ref={ ref }
                                color={ textColorInput }
                                colorScheme="brand"
                                focusBorderColor="#207077"
                                value={ address.addressLine1 }
                                onChange={ (e) =>
                                    setAddress({ ...address, addressLine1: e.target.value })
                                }
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Apt, Suite, etc. (optional)</FormLabel>
                            <InputDefaultText
                                id="address2"
                                placeholder="Unit #"
                                name="address2"
                                value={ address.addressLine2 }
                                onChange={ (e: any) =>
                                    setAddress({ ...address, addressLine2: e.target.value })
                                }
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>City</FormLabel>
                            <InputDefaultText
                                placeholder="City"
                                id="city"
                                name="city"
                                value={ address.city }
                                onChange={ (e: any) =>
                                    setAddress({ ...address, city: e.target.value })
                                }
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>State</FormLabel>
                            <InputDefaultText
                                placeholder="State"
                                id="state"
                                name="state"
                                value={ address.state }
                                onChange={ (e: any) =>
                                    setAddress({ ...address, state: e.target.value })
                                }
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Country</FormLabel>
                            <InputDefaultText
                                placeholder="Country"
                                id="country"
                                name="country"
                                value={ address.country }
                                onChange={ (e: any) =>
                                    setAddress({ ...address, country: e.target.value })
                                }
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Postal Code</FormLabel>
                            <InputDefaultText
                                placeholder="Postal Code"
                                id="postalCode"
                                name="postalCode"
                                value={ address.postalCode }
                                onChange={ (e: any) =>
                                    setAddress({ ...address, postalCode: e.target.value })
                                }
                            />
                        </FormControl>
                    </SimpleGrid>
                </Card>
                <Box my={ 4 }
                    textAlign={ 'center' }>
                    <Button
                        size={ 'lg' }
                        colorScheme={ 'brand' }
                        onClick={ handleSubmit(onSubmit) }
                    >
                        Save
                    </Button>
                </Box>
            </Box>
        </LoadingOverlay>
    );
}

export default observer(Profile);
