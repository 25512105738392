import {
  Avatar, Box,
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay, Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import userStore from 'store/dataStore/userStore';
import { MdEdit, MdDelete } from 'react-icons/md';
import Upload from './upload/Upload';
import { FileRejection } from 'react-dropzone';
import { useState } from 'react';
import { InfoIcon } from '@chakra-ui/icons';

interface IAvatarProps {
  [x: string]: any;
}

const EditableAvatar: React.FC<IAvatarProps> = ({ handleAvatarSave, src }) => {
  const [avatar, setAvatar] = useState<File | any>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const handleAvatarUpload = (
    acceptedFiles: File[],
    rejectedFiles: FileRejection[]
  ) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      const avatarWithDateAndPreview = Object.assign(file, {
        uploadDate: new Date().toISOString(),
        preview: URL.createObjectURL(file),
      });
      setAvatar(avatarWithDateAndPreview);
    }

    if (rejectedFiles.length > 0) {
      toast({
        title: 'Error occurred',
        description: 'Some of the files did not meet the requirements.',
        status: 'error',
        duration: 5000,
        position: 'bottom-right',
        isClosable: true,
      });
    }
  };

  const handleModalClose = () => {
    setAvatar(null);
    onClose();
  };

  return (
    <Box>
      <Box my={2} textAlign={'center'}>
        <Button
          onClick={onOpen}
          aria-label="Edit avatar"
          variant={'link'}
        >
          <Avatar
              color="white"
              onClick={onOpen}
              src={src}
              name={`${userStore.user?.firstName} ${userStore.user?.lastName}`}
              bg={'brand.500'}
              size={'xl'}
          />
        </Button>
        <Box mt={1}>
          <Button
              onClick={onOpen}
              aria-label="Edit avatar"
              variant={'link'}
          >
            Edit Avatar
          </Button>
        </Box>
      </Box>

      <Modal isOpen={isOpen} onClose={handleModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Avatar</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justifyContent={'center'}>
              <Avatar
                src={avatar && avatar.preview}
                onLoad={() => {
                  URL.revokeObjectURL(avatar && avatar.preview);
                }}
                bg="brand.500"
                size="xl"
                mb={2}
              />
            </Flex>
            <Upload
              handleFileUpload={handleAvatarUpload}
              imagesOnly={true}
              multiple={false}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={!avatar}
              variant="brand"
              mr={3}
              onClick={() => {
                handleAvatarSave(avatar);
                onClose();
              }}
            >
              Upload
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default EditableAvatar;
