import { useEffect } from 'react';
import { Alert, AlertIcon, Box, Flex, SimpleGrid, Text, useRadioGroup } from '@chakra-ui/react';
import LoadingOverlay from 'components/loader/LoadingOverlay';
import { observer } from 'mobx-react';
import accommodationStore from 'store/dataStore/accommodationStore';
import RadioCard from 'components/RadioCard/RadioCard';

interface ICategoriesProps {
    [x: string]: any;
}

const Categories: React.FC<ICategoriesProps> = (props) => {
    /* eslint-disable */
    useEffect(() => {
        if (accommodationStore.categories.length <= 0) {
            accommodationStore.getCategories();
        }
        if (accommodationStore.newRequest?.categoryId) { props.setIsValid(true); }
    }, [accommodationStore.newRequest?.categoryId]);

    const handleChange = (value: string) => {
        if (value) {
            accommodationStore.setNewRequestData({ categoryId: value });
        }
    }

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'category',
        value: accommodationStore.newRequest?.categoryId,
        onChange: (value: string) => { handleChange(value); },
    });
    const group = getRootProps();

    const getCategories = (): React.ReactElement => {
        if (accommodationStore?.categories.length > 0) {
            return (
                <SimpleGrid alignItems="center" justifyContent="center" mb={'5'} {...group} columns={[1, 1, 2, 3]} spacing={5}>
                    {accommodationStore.categories.map((category: any) => {
                        const { id: value, name } = category;
                        const radio = getRadioProps({ value });
                        return (
                            <RadioCard key={value} {...radio}>
                                {name}
                            </RadioCard>
                        );
                    })}
                </SimpleGrid>
            );
        }
        else {
            return (
                <Alert status='warning'>
                    <AlertIcon />
                    No Categories found.
                </Alert>
            );
        }
    }

    return (
        <LoadingOverlay isLoading={accommodationStore.loading}>
            <Box>
                <Text px={{ base: '0', lg: '15%' }} fontSize={{ base: 'xl', lg: '2xl' }} textAlign={'center'} fontWeight='bold'>
                    2. Select the category with a functional impact on your role
                </Text>
                <Flex mt={'20px'} justifyContent={'center'}>
                    <div>
                        {getCategories()}
                    </div>
                </Flex>
            </Box>
        </LoadingOverlay>
    );
}

export default observer(Categories);
