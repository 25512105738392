import { observable, action, makeObservable } from 'mobx';
import {
  NewAccommodationRequest,
  Category,
  SetNewRequestData,
  Disability,
  Question,
  TrackedAnswers,
  PresentingIssue,
} from 'store/models/accommodation';
import { request } from 'store/axios';
import { AxiosError, AxiosResponse } from 'axios';
import getErrorFromStatusCode from 'store/utils/axiosErrorUtils';
import { createStandaloneToast } from '@chakra-ui/react';
import { requestOptionForFormData } from 'store/axios/axios-setup';
const toast = createStandaloneToast();

enum AccommodationEndpoints {
  GET_CATEGORIES = '/api/wpas/categories',
  GET_RECOMMENDED_ACCOMMODATIONS = '/api/wpas/accommodations/recommendations',
  SUBMIT_ACCOMMODATION_REQUEST = '/api/wpas/accommodation-requests',
  GET_ATTACHMENT = '/api/wpas/attachments/:id',
  GET_DISABILITIES = '/api/wpas/disabilities',
  GET_DISABILITY_QUESTIONS = '/api/wpas/disabilities/:id/questions',
  GET_PRESENTING_ISSUES = '/api/wpas/presenting-issues',
  GET_PRESENTING_ISSUE_QUESTIONS = '/api/wpas/presenting-issues/:id/questions',
  GET_QUESTION_WITH_SUBQUESTIONS = '/api/wpas/questions/:id/sub-questions',
}

interface Ierrors {
  error: boolean;
  message: string;
}

interface AttachmentResponse {
  temporaryUrl: string;
}

class AccommodationStore {
  @observable loading: boolean = false;
  @observable categories: Category[] = [];
  @observable category: Category;
  @observable disabilities: Disability[] = [];
  @observable presentingIssues: PresentingIssue[] = [];

  @observable question: Question = {
    id: '',
    question_text: '',
    type: '',
    subQuestions: [],
  };

  @observable QuestionWithIntervalSubQuestion: Question = {
    id: '',
    question_text: '',
    type: '',
    subQuestions: [],
  };

  @observable answers: TrackedAnswers = {};

  @observable newRequest: NewAccommodationRequest = { accommodationId: '', attachments: []};
  @observable requestCompleteStatus: boolean = false;

  @observable errors: Ierrors = {
    error: false,
    message: '',
  };

  constructor() {
    makeObservable(this);
  }

  @action
  onSuccess = () => {
    this.errors = { error: false, message: '' };
  };

  onError = (error: string) => {
    toast({
      title: 'Error occurred',
      description: error,
      status: 'error',
      duration: 5000,
      position: 'bottom-right',
      isClosable: true,
    });
  };

  @action
  onSuccessWithFeedback = (description: string) => {
    this.errors = { error: false, message: '' };
    toast({
      title: 'Success!',
      description: description,
      status: 'success',
      isClosable: true,
      position: 'bottom-right',
    });
  };

  @action
  setErrors = (errors: Ierrors) => {
    this.errors = errors;
  };

  @action
  setLoading = (value: boolean = true) => {
    this.loading = value;
  };

  @action
  resetRequestData = () => {
    this.newRequest = { accommodationId: '', attachments: [] };
  };

  @action
  setRequestCompleteStatus = (value: boolean) => {
    this.requestCompleteStatus = value;
  };

  @action
  resetCategory = () => {
    this.category = {
      id: '',
      name: '',
      description: '',
    };
  };

  @action
  setNewRequestData = (data: SetNewRequestData) => {
    this.newRequest = {
      ...this.newRequest,
      ...data,
    };
  };

  @action
  clearNewRequestNotes = () => {
    this.newRequest = {
      ...this.newRequest,
      note: '',
    };
  };

  @action
  resetQuestionnaireFlow = () => {
    this.question = {
      id: '',
      question_text: '',
      type: '',
      subQuestions: [],
    };
    this.QuestionWithIntervalSubQuestion = {
      id: '',
      question_text: '',
      type: '',
      subQuestions: [],
    };
  };

  @action
  setAnswers = (questionId: string, value: any) => {
    this.answers = {
      ...this.answers,
      [questionId]: value,
    };
  };

  @action
  getRecommendedAccommodations = async (data: {}) => {
    this.setLoading(true);
    try {
      const response = await request.post<AxiosResponse>(
        AccommodationEndpoints.GET_RECOMMENDED_ACCOMMODATIONS,
        data
      );
      this.onSuccess();
      return response.data.data;
    } catch (err: AxiosError | any) {
      this.onError(getErrorFromStatusCode(err));
    } finally {
      this.setLoading(false);
    }
  };

  @action
  getCategories = async () => {
    this.setLoading(true);
    try {
      const response = await request.get<AxiosResponse>(
        AccommodationEndpoints.GET_CATEGORIES
      );
      this.onSuccess();
      this.categories = response.data.data;
    } catch (err: AxiosError | any) {
      this.onError(getErrorFromStatusCode(err));
    } finally {
      this.setLoading(false);
    }
  };

  @action
  getCategoryWithAccommodations = async (id: string) => {
    this.setLoading(true);
    try {
      const response = await request.get<AxiosResponse>(
        `/api/wpas/categories/${id}/accommodations`
      );
      this.onSuccess();
      this.category = response.data.data;
    } catch (err: AxiosError | any) {
      this.onError(getErrorFromStatusCode(err));
    } finally {
      this.setLoading(false);
    }
  };

  @action
  createAccommodationRequest = async (
    id: string,
    notes: string,
    attachments?: File[]
  ) => {
    try {
      let formData = new FormData();
      formData.append('accommodationId', id ?? '');
      formData.append('note', notes ?? '');

      if (attachments?.length > 0) {
        attachments.forEach((file: File) => {
          formData.append('attachments[]', file);
        });
      }

      const response = await request.post<AxiosResponse>(
        AccommodationEndpoints.SUBMIT_ACCOMMODATION_REQUEST,
        formData,
        requestOptionForFormData
      );
      this.resetRequestData();
      return response.data;
    } catch (err: AxiosError | any) {
      this.onError(err.response.data.message);
      return Promise.reject(err.response);
    }
  };

  @action
  getAttachment = async (id: string) => {
    this.setLoading(true);
    try {
      const response = await request.get<AttachmentResponse>(
        AccommodationEndpoints.GET_ATTACHMENT.replace(':id', id)
      );
      const downloadUrl = response.data.temporaryUrl;
      this.downloadAttachment(downloadUrl);
    } catch (err: AxiosError | any) {
      this.onError(getErrorFromStatusCode(err));
    } finally {
      this.setLoading(false);
    }
  };

  downloadAttachment = (downloadUrl: string) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = downloadUrl;
    downloadLink.click();
  };

  @action
  getDisabilities = async () => {
    this.setLoading(true);
    try {
      const response = await request.get<AxiosResponse>(
        AccommodationEndpoints.GET_DISABILITIES
      );
      this.disabilities = response.data.data;
      this.onSuccess();
    } catch (err: AxiosError | any) {
      this.onError(getErrorFromStatusCode(err));
    } finally {
      this.setLoading(false);
    }
  };

  @action
  getPresentingIssues = async () => {
    this.setLoading(true);
    try {
      const response = await request.get<AxiosResponse>(
        AccommodationEndpoints.GET_PRESENTING_ISSUES
      );
      this.presentingIssues = response.data.data;
      this.onSuccess();
    } catch (err: AxiosError | any) {
      this.onError(getErrorFromStatusCode(err));
    } finally {
      this.setLoading(false);
    }
  };

  @action
  getDisabilityQuestions = async (id: string) => {
    try {
      const response = await request.get<AxiosResponse>(
        AccommodationEndpoints.GET_DISABILITY_QUESTIONS.replace(':id', id)
      );
      this.onSuccess();
      return response.data.data.questions;
    } catch (err: AxiosError | any) {
      this.onError(getErrorFromStatusCode(err));
    } finally {
      this.setLoading(false);
    }
  };

  @action
  getPresentingIssueQuestions = async (id: string) => {
    try {
      const response = await request.get<AxiosResponse>(
        AccommodationEndpoints.GET_PRESENTING_ISSUE_QUESTIONS.replace(':id', id)
      );
      this.onSuccess();
      return response.data.data.questions;
    } catch (err: AxiosError | any) {
      this.onError(getErrorFromStatusCode(err));
    } finally {
      this.setLoading(false);
    }
  };

  @action
  getQuestionsWithSubQuestions = async (id: string) => {
    // this.setLoading(true);
    try {
      const response = await request.get<AxiosResponse>(
        AccommodationEndpoints.GET_QUESTION_WITH_SUBQUESTIONS.replace(':id', id)
      );
      this.question = await response.data.data;
      this.onSuccess();
      return response.data.data.subQuestions;
    } catch (err: AxiosError | any) {
      this.onError(getErrorFromStatusCode(err));
    } finally {
      this.setLoading(false);
    }
  };

  @action
  getQuestionsWithIntervalSubQuestions = async (id: string) => {
    this.setLoading(true);
    try {
      const response = await request.get<AxiosResponse>(
        AccommodationEndpoints.GET_QUESTION_WITH_SUBQUESTIONS.replace(':id', id)
      );
      this.QuestionWithIntervalSubQuestion = await response.data.data;
      this.onSuccess();
      return response.data.data.subQuestions;
    } catch (err: AxiosError | any) {
      this.onError(getErrorFromStatusCode(err));
    } finally {
      this.setLoading(false);
    }
  };

  @action
  getPrediction = async () => {
    try {
      let formData = new FormData();

      this.newRequest.attachments.forEach((file: File) => {
        formData.append('attachments[]', file);
      });

      const response = await  request.post('/api/wpas/predict', formData, requestOptionForFormData);

      this.onSuccess();
      return response.data;
    }catch (error) {
      this.onError(getErrorFromStatusCode(error));
    }
  }

}

const accommodationStore = new AccommodationStore();
export default accommodationStore;
