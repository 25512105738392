import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import App from 'App';
import './assets/css/App.css';
import theme from './theme/theme';
import UserStore from './store/dataStore/userStore';
import accommodationStore from 'store/dataStore/accommodationStore';

const Stores = {
	UserStore,
	accommodationStore
}

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
			<Provider {...Stores}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</Provider>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);
