import PropTypes from 'prop-types';
import { Flex } from '@chakra-ui/react';

function AuthIllustration(props: { children: JSX.Element | string; illustrationBackground: string }) {
	const { children } = props;
	return (
		<Flex justifyContent='center' alignItems='center' h='max-content' flexDirection='column'>
			{children}
		</Flex>
	);
}

AuthIllustration.propTypes = {
	image: PropTypes.any
};

export default AuthIllustration;
