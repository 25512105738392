import { Box } from '@chakra-ui/react';
import ReactQuill from 'react-quill';

interface ReactQuillEditorProps {
    [x: string]: any;
    handleChange?: (value: string) => void;
};

const ReactQuillEditor: React.FC<ReactQuillEditorProps> = (props) => {

    const toolbarOptions = [
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline'],
        ['link'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    ];

    const modules = {
        toolbar: toolbarOptions,
        keyboard:{ bindings:{ tab: false } }
    }

    return (<Box w={'100%'}><ReactQuill className="customQuillEditor" modules={modules} value={props.value} onChange={(notes: string) => props.handleChange(notes)} /></Box>);
}

export default ReactQuillEditor;
