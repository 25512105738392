import React, { useState } from 'react';
import {
    Box,
    Button,
    HStack,
    Icon,
    IconButton,
    SimpleGrid,
    Text,
    useBreakpointValue,
    useToast
} from '@chakra-ui/react';
import ReactQuillEditor from 'components/reactQuillEditor/ReactQuillEditor';
import { getFormatterStringFromNotes } from 'utils/StringFormatter';
import Upload from 'components/upload/Upload';
import TableWithRemoveAction from 'components/tables/CustomSimpleTable';
import { createColumnHelper } from '@tanstack/react-table';
import Moment from 'react-moment';
import { FileRejection } from 'react-dropzone';
import { MdDeleteForever } from 'react-icons/md';
import accommodationStore from 'store/dataStore/accommodationStore';
import LoadingOverlay from 'components/loader/LoadingOverlay';
import { observer } from 'mobx-react';

interface ISubmitFormProps {
    accommodationId: string;
    [x: string]: any;
}

const SubmitForm: React.FC<ISubmitFormProps> = (props) => {
    const toast = useToast();
    const buttonSize = useBreakpointValue({ base: 'md', lg: 'lg' }, { ssr: false } as any);
    const [valid, setValid] = useState(false);
    const [notes, setNotes] = useState('');
    const [attachments, setAttachments] = useState<File[]>([]);
    const [loading, setLoading] = useState(false);

    const { accommodationId } = props;

    const handleNotesChange = (notes: string) => {
        setNotes(notes);
        const formattedText = getFormatterStringFromNotes(notes);
        if (formattedText.trim().length > 0) {
            setValid(true);
        }
        else { setValid(false); }
    };

    const handleDeleteRow = (fileToRemove: File) => {
        const updatedFiles = attachments.filter((file) => file !== fileToRemove);
        setAttachments(updatedFiles);
    }

    const columnHelper = createColumnHelper<any>();
    const columns: any = [
        columnHelper.accessor("name", {
            cell: (data) => data.getValue(),
            header: "File Name"
        }),
        columnHelper.accessor("uploadDate", {
            cell: (data) => {
                const date = data.getValue()
                return (<Moment format="MM/DD/YYYY">{date}</Moment>)
            },
            header: "Upload Date"
        }),
        columnHelper.display({
            id: 'actions',
            cell: (data) => <IconButton colorScheme={'brand'} onClick={() => handleDeleteRow(data.row.original)} aria-label='Delete Attachment' icon={<Icon as={MdDeleteForever} />}
            />,
        }),
    ];

    const handleFileUpload = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        if (acceptedFiles.length > 0) {
            const existingFiles: File[] = attachments;
            const newFiles: File[] = acceptedFiles.filter((file: File) => !existingFiles.find(f => f.name === file.name));
            const newFilesWithUploadDate = newFiles.map((file: File) => {
                return Object.assign(file, { uploadDate: new Date().toISOString() });
            });
            setAttachments([...existingFiles, ...newFilesWithUploadDate]);
        }

        if (rejectedFiles.length > 0) {
            toast({
                title: 'Error occurred',
                description: 'Some of the files did not meet the requirements.',
                status: 'error',
                duration: 5000,
                position: 'bottom-right',
                isClosable: true
            });
        }
    };

    const clearRequest = (): void => {
        setNotes('');
        setAttachments([]);
    }

    const handleSubmitRequest = async () => {
        setLoading(true);
        const response = await accommodationStore.createAccommodationRequest(accommodationId, notes, attachments);
        if (response) {
            clearRequest();
        }
        setLoading(false);
    }

    return (
        <LoadingOverlay isLoading={accommodationStore.loading}>
            <Box mt={'20px'}>
                <LoadingOverlay isLoading={loading}>
                    <Text align={'left'} px={{ base: '0' }} fontSize={{ base: 'lg', lg: 'xl' }} fontWeight='bold'>
                        Please share how the functional impact impacts your role and the relevant accommodation:
                    </Text>
                    <ReactQuillEditor value={notes} handleChange={handleNotesChange} />
                    <Text pt={'15px'} align={'left'} px={{ base: '0' }} fontSize={{ base: 'lg', lg: 'xl' }} fontWeight='bold'>
                        Please upload any supporting documents:
                    </Text>
                    <SimpleGrid columns={[1, 1, 1, 2]} pt={'15px'} pb={'50px'} spacing={5}>
                        <Upload handleFileUpload={handleFileUpload} />
                        <Box border={'1px solid brand'}
                            borderRadius={'10px'}
                            maxHeight={'200px'}
                            overflowY={'auto'}>
                            {attachments.length > 0 ?
                                <TableWithRemoveAction columns={columns} data={attachments} /> : ''
                            }
                        </Box>
                    </SimpleGrid>
                    <HStack justifyContent={'center'}>
                        <Button onClick={() => clearRequest()} size={buttonSize} colorScheme='brand' variant={'outline'}>
                            Clear
                        </Button>
                        <Button
                            disabled={!(valid && notes.length > 0 && accommodationId)}
                            onClick={() => handleSubmitRequest()} size={buttonSize} colorScheme='brand'>
                            Submit
                        </Button>
                    </HStack>
                </LoadingOverlay>
            </Box>
        </LoadingOverlay>
    );
}

export default observer(SubmitForm);
