import { Grid, Box } from '@chakra-ui/react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import Card from 'components/card/Card';

export default function Settings() {
  return (
    <Box pt={{ base: '180px', md: '150px', xl: '100px' }}>
      <Card>
        <Grid>
          <Tabs colorScheme={'brand'} size={'md'} isLazy={true} >
            <TabList>
              <Tab>Accessibility Options</Tab>
              <Tab>Notification Settings</Tab>
              <Tab>Edit Consent</Tab>
              <Tab>Language Preferences</Tab>
              <Tab>Security Settings</Tab>
              <Tab>WPAS Feedback</Tab>
              <Tab>Account Recovery</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Grid>
      </Card>
    </Box>
  );
}
