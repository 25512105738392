import { AccommodationRequestSubmitted } from './AccommodationRequestSubmitted';
import { AccommodationRequestCaseManagerAssigned } from './AccommodationRequestCaseManagerAssigned';
import { AccommodationRequestDenied } from './AccommodationRequestDenied';
import { AccommodationRequestImplemented } from './AccommodationRequestImplemented';
import { AccommodationRequestInProgress } from './AccommodationRequestInProgress';
import { AccommodationRequestWithdrawn } from './AccommodationRequestWithdrawn';

export enum NotificationTypeEnum {
    ACCOMMODATION_REQUEST_CASE_MANAGER_ASSIGNED = 'accommodationRequest.caseManagerAssigned',
    ACCOMMODATION_REQUEST_DENIED = 'accommodationRequest.denied',
    ACCOMMODATION_REQUEST_IMPLEMENTED = 'accommodationRequest.implemented',
    ACCOMMODATION_REQUEST_IN_PROGRESS = 'accommodationRequest.inProgress',
    ACCOMMODATION_REQUEST_SUBMITTED = 'accommodationRequest.submitted',
    ACCOMMODATION_REQUEST_WITHDRAWN = 'accommodationRequest.withdrawn',
}

type INotificationType = {
    [key in NotificationTypeEnum]: {
        component: any;
    };
};

export const NotificationType: INotificationType = {
    [NotificationTypeEnum.ACCOMMODATION_REQUEST_CASE_MANAGER_ASSIGNED]: {
        component: AccommodationRequestCaseManagerAssigned,
    },
    [NotificationTypeEnum.ACCOMMODATION_REQUEST_DENIED]: {
        component: AccommodationRequestDenied,
    },
    [NotificationTypeEnum.ACCOMMODATION_REQUEST_IMPLEMENTED]: {
        component: AccommodationRequestImplemented,
    },
    [NotificationTypeEnum.ACCOMMODATION_REQUEST_IN_PROGRESS]: {
        component: AccommodationRequestInProgress,
    },
    [NotificationTypeEnum.ACCOMMODATION_REQUEST_SUBMITTED]: {
        component: AccommodationRequestSubmitted,
    },
    [NotificationTypeEnum.ACCOMMODATION_REQUEST_WITHDRAWN]: {
        component: AccommodationRequestWithdrawn,
    },
};
