import { useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useForm, SubmitHandler } from 'react-hook-form';
import DefaultAuth from 'layouts/auth/Default';
import illustration from 'assets/img/auth/auth.png';
import userStore from 'store/dataStore/userStore';
import PasswordResetLinkSentScreen from '../components/PasswordResetLinkSentScreen';

interface IForgotPasswordFormData {
  email: string;
}

function ForgotPassword() {
  useEffect(() => {
    userStore.passwordResetReqComplete = false;
  }, [])

  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.500';
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const textColorInput = useColorModeValue('secondaryGray.900', 'white');

  const onSubmit: SubmitHandler<IForgotPasswordFormData> = async (forgotPasswordFormData) => {
    await userStore.forgotPassword(forgotPasswordFormData);
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting }
  } = useForm<IForgotPasswordFormData>();

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      {userStore.passwordResetReqComplete ?
        <PasswordResetLinkSentScreen />
        :
        <Flex
          maxW={{ base: '100%', md: 'max-content' }}
          w='100%'
          mx={{ base: 'auto', lg: '0px' }}
          me='auto'
          h='100%'
          alignItems='start'
          justifyContent='center'
          mb={{ base: '30px', md: '60px' }}
          px={{ base: '25px', md: '0px' }}
          mt={{ base: '40px', md: '10vh' }}
          flexDirection='column'>
          <Box me='auto'>
            <Heading color={textColor} fontSize='36px' mb='10px'>
              Reset your password
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColorSecondary}
              fontWeight='400'
              fontSize='md'>
              To reset your password, enter the email address you use to sign in.
            </Text>
          </Box>
          <Flex
            zIndex='2'
            direction='column'
            w={{ base: '100%', md: '420px' }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            mx={{ base: 'auto', lg: 'unset' }}
            me='auto'
            mb={{ base: '20px', md: 'auto' }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl isInvalid={!!errors.email ?? true} mb='24px'>
                <FormLabel
                  htmlFor='email'
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                  Email<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  id='email'
                  name='email'
                  fontSize='sm'
                  color={textColorInput}
                  focusBorderColor='#207077'
                  ms={{ base: '0px', md: '0px' }}
                  type='email'
                  placeholder='mail@simmmple.com'
                  fontWeight='500'
                  size='lg'
                  isDisabled={userStore.loading || isSubmitting}
                  {...register('email', {
                    required: 'This is required',
                  })}
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>
              <Flex justifyContent='space-between' align='center' mb='24px'>
                <NavLink to='/auth/login'>
                  <Text
                    color={textColorBrand}
                    fontSize='sm'
                    w='124px'
                    fontWeight='500'>
                    &lt; Back to sign in
                  </Text>
                </NavLink>
              </Flex>
              <Button
                type='submit'
                fontSize='sm'
                colorScheme='brand'
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'
                isLoading={userStore.loading || isSubmitting}>
                Get reset link
              </Button>
            </form>
          </Flex>
        </Flex>
      }
    </DefaultAuth >
  );
}

export default observer(ForgotPassword);
