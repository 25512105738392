import { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Icon,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  FormErrorMessage,
} from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import DefaultAuth from 'layouts/auth/Default';
import illustration from 'assets/img/auth/auth.png';
import userStore from 'store/dataStore/userStore';
import * as Constants from '../../../utils/Constants';

interface IRegisterFormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

function Register() {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<IRegisterFormData>();

  const onSubmit: SubmitHandler<IRegisterFormData> = async (
    registerFormData
  ) => {
    userStore
      .register(registerFormData)
      .then((response: any) => {
        localStorage.setItem(
          Constants.LOCAL_STORAGE_USER_LOGGED_IN_KEY,
          'true'
        );
        localStorage.setItem(
            'access_token',
            response.access_token
        );
        localStorage.setItem(
            'refresh_token',
            response.refresh_token
        );
        localStorage.setItem(
            'token_expiry_time',
            response.token_expiry_time
        );

        localStorage.setItem(
          Constants.LOCAL_STORAGE_USER_DATA_KEY,
          JSON.stringify(response.user)
        );
        history.push('/employee');
      })
      .catch((error) => {
        userStore.setErrors({
          status: error.status,
          message: error.data.message,
        });
      });
  };

  const textColor = useColorModeValue('navy.700', 'white');
  const textColorInput = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.500';
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w='100%'
        mx={{ base: 'auto', lg: '0px' }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '10vh' }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Create an W-PAS Account
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Enter defails below to register!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: '100%', md: '420px' }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: 'auto', lg: 'unset' }}
          me='auto'
          mb={{ base: '20px', md: 'auto' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={!!errors.firstName ?? true} mb='24px'>
              <FormLabel
                htmlFor='firstName'
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                First Name<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                id='firstName'
                name='firstName'
                color={textColorInput}
                focusBorderColor='#207077'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                type='text'
                placeholder='john'
                fontWeight='500'
                size='lg'
                isDisabled={userStore.loading || isSubmitting}
                {...register('firstName', {
                  required: 'This is required',
                })}
              />
              <FormErrorMessage>
                {errors.firstName && errors.firstName.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.lastName ?? true} mb='24px'>
              <FormLabel
                htmlFor='lastName'
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Last Name<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                id='lastName'
                name='lastName'
                color={textColorInput}
                focusBorderColor='#207077'
                fontSize='sm'
                type='text'
                placeholder='Doe'
                fontWeight='500'
                size='lg'
                isDisabled={userStore.loading || isSubmitting}
                {...register('lastName', {
                  required: 'This is required',
                })}
              />
              <FormErrorMessage>
                {errors.lastName && errors.lastName.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.email ?? true} mb='24px'>
              <FormLabel
                htmlFor='email'
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Email Address<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                id='email'
                name='email'
                color={textColorInput}
                focusBorderColor='#207077'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                type='email'
                placeholder='mail@simmmple.com'
                fontWeight='500'
                size='lg'
                isDisabled={userStore.loading || isSubmitting}
                {...register('email', {
                  required: 'This is required',
                })}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.password ?? true} mb='24px'>
              <FormLabel
                htmlFor='password'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  id='password'
                  name='password'
                  fontSize='sm'
                  placeholder='Min. 8 characters'
                  size='lg'
                  type={show ? 'text' : 'password'}
                  color={textColorInput}
                  focusBorderColor='#207077'
                  isDisabled={userStore.loading || isSubmitting}
                  {...register('password', {
                    required: 'This is required',
                    minLength: {
                      value: 8,
                      message: 'Minimum length should be 8 characters',
                    },
                  })}
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: 'pointer' }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={() => {
                      setShow(!show);
                    }}
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </FormControl>
            <Button
              type='submit'
              isLoading={userStore.loading || isSubmitting}
              loadingText='Loading'
              fontSize='sm'
              colorScheme='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'>
              Submit
            </Button>
          </form>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Already have an account?
              <NavLink to='/auth/login'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Sign in
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default observer(Register);
