import { Alert, AlertIcon, Box, Flex, SimpleGrid, Text, useRadioGroup } from '@chakra-ui/react';
import { observer } from 'mobx-react';

import LoadingOverlay from 'components/loader/LoadingOverlay';
import accommodationStore from 'store/dataStore/accommodationStore';
import { useEffect, useState } from 'react';
import RadioCard from '../../../../components/RadioCard/RadioCard';

interface IPresentingIssuesProps {
    [x: string]: any;
}

const PresentingIssues: React.FC<IPresentingIssuesProps> = (props) => {
    const [presentingIssueId, setPresentingIssueId] = useState(null);

    useEffect(() => {
        accommodationStore.getPresentingIssues();
    }, []);

    const handleChange = (value: string) => {
        setPresentingIssueId(value);
        props.onPresentingIssueSelect({
            presentingIssueId: value
        });
    }

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'presentingIssueId',
        value: presentingIssueId,
        onChange: (value: string) => { handleChange(value); },
    });

    const group = getRootProps();

    const renderPresentingIssues = (): React.ReactElement => {
        return (
            <>
                <SimpleGrid alignItems="center" justifyContent="center" mb={'5'} {...group} columns={[1, 1, 2, 3]} spacing={5}>
                    {accommodationStore.presentingIssues.map((issue: any) => {
                        const { id: value, name } = issue;
                        const radio = getRadioProps({ value });
                        return (
                            <RadioCard key={value} {...radio}>
                                {name}
                            </RadioCard>
                        );
                    })}
                </SimpleGrid>
                {
                    !accommodationStore.presentingIssues.length ?
                        <Alert status='warning'>
                            <AlertIcon />
                            No Presenting Issues found.
                        </Alert>
                        : ''
                }
            </>
        );
    }

    return (
        <LoadingOverlay isLoading={accommodationStore.loading}>
            <Box>
                <Text px={{ base: '0', lg: '15%' }} fontSize={{ base: 'xl', lg: '2xl' }} textAlign={'center'} fontWeight='bold'>
                    Do you have any presenting issues? Please select from the following:
                </Text>
                <Flex mt={'20px'} justifyContent={'center'}>
                    <div>
                        {renderPresentingIssues()}
                    </div>
                </Flex>
            </Box>
        </LoadingOverlay>
    );
}

export default observer(PresentingIssues);
