import { Link } from 'react-router-dom';
import { Flex, Image } from '@chakra-ui/react';
import { HSeparator } from 'components/separator/Separator';
import wpasImage from 'assets/img/wpas/wpasLogoNew.svg';

export function SidebarBrand() {
	return (
		<Flex alignItems='center' justifyContent={'center'} flexDirection='column'>
			<Link to='/employee'><Image alt='wpas-logo' maxH={20} src={wpasImage} /></Link>
			<HSeparator my='20px' />
		</Flex>
	);
}

export default SidebarBrand;
