import React, { useState, useRef } from 'react';
import { toJS } from 'mobx';

import {
  Box,
  Button,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { observer } from 'mobx-react';
import ReactQuill from 'react-quill';

import LoadingOverlay from 'components/loader/LoadingOverlay';
import accommodationStore from 'store/dataStore/accommodationStore';
import { NewAccommodationRequest } from 'store/models/accommodation';
import accommodationRequestStore from '../../../../store/dataStore/accommodationRequestStore';
import { StatusEnum } from 'store/models/status';

//@TODO::Refactor the file to distinguish between create and submit accommodation request

const toolbarOptions = [
  [{ size: ['small', false, 'large', 'huge'] }],
  ['bold', 'italic', 'underline'],
  ['link'],
  [{ list: 'ordered' }, { list: 'bullet' }],
];

const modules = {
  toolbar: toolbarOptions,
  keyboard: {
    bindings: {
      tab: {
        key: 9,
        handler: function () {
          return true;
        },
      },
    },
  },
};

const CreateAccommodationRequest = () => {
  const editorRef = useRef<ReactQuill>(null); // reference to the ReactQuill editor
  const [valid, setValid] = useState(false);
  const [loadingState, setLoadingState] = useState({ isLoading: false, message: '' });

  const buttonSize = useBreakpointValue({ base: 'md', lg: 'lg' }, {
    ssr: false,
  } as any);

  const getFormatterString = (notes: string) => {
    const plainText = document.createElement('div');
    plainText.innerHTML = notes;
    return plainText.innerText;
  };

  const handleNotesChange = (notes: string) => {
    accommodationStore.setNewRequestData({ note: notes });
    const formattedText = getFormatterString(notes);
    if (formattedText.trim().length > 0) {
      setValid(true);
    } else {
      setValid(false);
      accommodationStore.setNewRequestData({ note: '' });
    }
  };

  const handleSubmitRequest = (submitted = false): void => {
    const newRequestData: NewAccommodationRequest = toJS(
      accommodationStore.newRequest
    );

    setLoadingState({ isLoading: true, message: "Creating Accommodation Request" });
    accommodationStore.createAccommodationRequest(
        newRequestData.accommodationId,
        newRequestData.note,
        newRequestData.attachments
    ).then(async (response) => {
      let description = 'Accommodation Request Created Successfully';
      if (submitted) {
        setLoadingState({ isLoading: true, message: "Submitting Accommodation Request" });
        await accommodationRequestStore.updateStatus(response.data.id, StatusEnum.SUBMITTED);
        description = 'Accommodation Request Created and Submitted Successfully';
      }
      accommodationStore.setRequestCompleteStatus(true);
      accommodationStore.onSuccessWithFeedback(description);
    }).catch((error) => {
      //TODO::some error handling here
      console.log(error);
    }).finally(() => {
      setLoadingState({ isLoading: false, message: "" });
    });
  };

  return (
    <LoadingOverlay isLoading={loadingState.isLoading} message={loadingState.message}>
      <Box px={{ base: '0', lg: '10%' }}>
        <Text
          align={'left'}
          px={{ base: '0' }}
          fontSize={{ base: 'xl', lg: '2xl' }}
          fontWeight='bold'
        >
          4. Describe the functional impact on your role and responsibilities
        </Text>
        <Box my={5}>
          <ReactQuill
            ref={editorRef}
            className='customQuillEditor'
            modules={modules}
            value={accommodationStore.newRequest.note}
            onChange={handleNotesChange}
          />
        </Box>
        <Box textAlign={'center'}>
          <Button
              onClick={() => handleSubmitRequest()}
              size={ buttonSize }
              mx={2}
              colorScheme='brand'
              variant={ 'outline' }
          >
            Save and Exit
          </Button>
          <Button
              disabled={
                  valid &&
                  (accommodationStore.newRequest.note &&
                      !accommodationStore.newRequest.note.length)
              }
              mx={2}
              onClick={() => handleSubmitRequest(true)}
              size={buttonSize}
              colorScheme='brand'
          >
            Submit
          </Button>
        </Box>
      </Box>
    </LoadingOverlay>
  );
};

export default observer(CreateAccommodationRequest);
