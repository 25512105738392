import React, { useState } from 'react';
import { Button, Flex, useColorModeValue } from '@chakra-ui/react';
import { useDropzone, FileRejection } from 'react-dropzone';

interface IDropzoneProps {
  handleFileUpload: (
    acceptedFileTypes: File[],
    rejectedFiles: FileRejection[]
  ) => void;
  imagesOnly?: boolean;
  multiple?: boolean;
  content: JSX.Element;
  [x: string]: any;
}

const defaultAcceptedFileTypes = ['application/pdf', 'image/jpeg', 'image/png', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
const imageFileTypes = ['image/jpeg', 'image/png'];

const Dropzone: React.FC<IDropzoneProps> = (props) => {
  const [files, setFiles] = useState<File[]>([]);
  const { content, handleFileUpload, imagesOnly, multiple } = props;
  const { getRootProps, getInputProps } = useDropzone({
    accept: imagesOnly ? imageFileTypes : defaultAcceptedFileTypes,
    maxSize: 5242880, // 5MB
    onDrop: (acceptedFiles, rejectedFiles) => {
      handleFileUpload(acceptedFiles, rejectedFiles);
      setFiles([...files, ...acceptedFiles]);
    },
    multiple: multiple ?? true,
  });

  const bg = useColorModeValue('gray.100', 'navy.700');
  const borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    const fileArray = Array.from(fileList);
    setFiles(fileArray);
  };

  return (
    <>
      <Flex
        align="center"
        justify="center"
        bg={bg}
        border="1px dashed"
        borderColor={borderColor}
        borderRadius="16px"
        w="100%"
        h="200px"
        minH="100%"
        cursor="pointer"
        {...getRootProps({ className: 'dropzone' })}
      >
        <input tabIndex={-1} {...getInputProps()} onChange={handleChange} />
        <Button
          _focus={{ boxShadow: 'none' }}
          tabIndex={-1}
          variant="no-effects"
        >
          {content}
        </Button>
      </Flex>
    </>
  );
};

export default Dropzone;
