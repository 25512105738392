import { useEffect } from 'react';

export default function useGoogleTranslateElement() {
    useEffect(() => {
        const googleTranslateElementInit = () => {
            new window.google.translate.TranslateElement(
                { pageLanguage: 'en' },
                'google_translate_element'
            );
        };

        const script = document.createElement('script');
        script.src =
            '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
        script.async = true;
        document.body.appendChild(script);
        window.googleTranslateElementInit = googleTranslateElementInit;

        return () => {
            document.body.removeChild(script);
        };
    }, []);
}

