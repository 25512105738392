import { observer } from 'mobx-react';
import * as React from 'react';
import { Box, Button, Flex, Select, Table, Tbody, Td, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import {
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
} from '@tanstack/react-table';

export default observer(function CustomComplexTable(props: { tableData: any, columns: any[], pagination?: boolean }) {
	const { tableData, columns } = props;
	/*eslint-disable  */
	const [sorting, setSorting] = React.useState<SortingState>([]);
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	let defaultData = tableData ? tableData : [];
	const data = defaultData;
	const defaultSorting = [
		{
			id: 'updatedAt',
			desc: true, // set to true for descending order
		},
	];
	const table = useReactTable({
		data,
		columns,
		state: { sorting: defaultSorting },
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		// getPaginationRowModel: getPaginationRowModel(),
		// initialState: { pagination: { pageSize: 5 }, }
	});

	return (
		<Box>
			<Table variant='striped' color='gray.500' mb='24px' mt="12px">
				<Thead>
					{table.getHeaderGroups().map((headerGroup) => (
						<Tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<Th
										key={header.id}
										colSpan={header.colSpan}
										pe='10px'
										borderColor={borderColor}
										cursor='pointer'
										onClick={header.column.getToggleSortingHandler()}>
										<Flex
											justifyContent='start'
											align='center'
											fontSize={{ sm: '10px', lg: '12px' }}
											color='secondaryGray.600'>
											{flexRender(header.column.columnDef.header, header.getContext())}{{
												asc: '↑',
												desc: '↓',
											}[header.column.getIsSorted() as string] ?? null}
										</Flex>
									</Th>
								);
							})}
						</Tr>
					))}
				</Thead>

				<Tbody>
					{table.getRowModel().rows.length > 0 ?
						table.getRowModel().rows.map((row) => {
							return (
								<Tr key={row.id}>
									{row.getVisibleCells().map((cell) => {
										return (
											<Td
												key={cell.id}
												fontSize={{ base: '24px' }}
												minW={{ base: 'auto' }}
												borderColor='transparent'>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</Td>
										);
									})}
								</Tr>
							);
						}) :
						(
							<Tr>
								<Td colSpan={5}>No data to display.</Td>
							</Tr>
						)}
				</Tbody>
			</Table>
			{props.pagination ?
				<Flex px={5} justifyContent={'space-between'}>
					<Flex justifyContent={'space-between'}>
						<div>
							<Button
								m={0.5}
								colorScheme={'brand'}
								variant={'outline'}
								className="border rounded p-1"
								onClick={() => table.setPageIndex(0)}
								disabled={!table.getCanPreviousPage()}
								display={{ base: 'none', md: 'unset' }}
							>
								{'<<'}
							</Button>
							<Button
								m={0.5}
								colorScheme={'brand'}
								className="border rounded p-1 d-none"
								onClick={() => table.previousPage()}
								disabled={!table.getCanPreviousPage()}
							>
								{'<'}
							</Button>
							<Button
								m={0.5}
								colorScheme={'brand'}
								className="border rounded p-1"
								onClick={() => table.nextPage()}
								disabled={!table.getCanNextPage()}
							>
								{'>'}
							</Button>
							<Button
								m={0.5}
								colorScheme={'brand'}
								variant={'outline'}
								className="border rounded p-1"
								onClick={() => table.setPageIndex(table.getPageCount() - 1)}
								disabled={!table.getCanNextPage()}
								display={{ base: 'none', md: 'unset' }}
							>
								{'>>'}
							</Button>
						</div>
						<Flex alignItems={'center'} pl={'5px'}>
							<div>Page&nbsp;</div>
							<strong>
								{table.getState().pagination.pageIndex + 1} of{' '}
								{table.getPageCount()}
							</strong>
						</Flex>
					</Flex>
					<Flex justifyContent={{ base: 'end' }}>
						<Select
							width={'120px'}
							value={table.getState().pagination.pageSize}
							onChange={e => {
								table.setPageSize(Number(e.target.value))
							}}
						>
							{[5, 10, 15, 20].map(pageSize => (
								<option key={pageSize} value={pageSize}>
									Show {pageSize}
								</option>
							))}
						</Select>
					</Flex>
				</Flex>
				: ''}

		</Box>
	);
});
