import { Button, Input } from '@chakra-ui/react';
import React from 'react';

import { CountrySelector, usePhoneInput } from 'react-international-phone';

interface ChakraPhoneProps {
  value: string;
  onChange: (phone: string) => void;
}

export const ChakraPhone: React.FC<ChakraPhoneProps> = ({
  value,
  onChange,
}) => {
  const phoneInput = usePhoneInput({
    defaultCountry: 'us',
    value,
    onChange: (data) => {
      onChange(data.phone);
    },
  });

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <CountrySelector
        selectedCountry={phoneInput.country}
        onSelect={(country) => phoneInput.setCountry(country.iso2)}
        renderButtonWrapper={({ children, rootProps }) => (
          <Button {...rootProps} variant="outline" px="4px" mr="8px">
            {children}
          </Button>
        )}
      />
      <Input
        focusBorderColor="#207077"
        placeholder="Phone number"
        type="tel"
        value={phoneInput.phone}
        onChange={phoneInput.handlePhoneValueChange}
        width={200}
        ref={phoneInput.inputRef}
      />
    </div>
  );
};
