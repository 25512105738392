import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Alert, AlertIcon, Box, Flex, Input, InputGroup, InputLeftElement, SimpleGrid, Text, useColorModeValue, useRadio, useRadioGroup } from '@chakra-ui/react';
import LoadingOverlay from 'components/loader/LoadingOverlay';
import accommodationStore from 'store/dataStore/accommodationStore';
import { InfoIcon, SearchIcon } from '@chakra-ui/icons';

interface IAccommodationsProps {
    [x: string]: any;
}

interface Accommodation {
    id: string;
    name: string;
    description: string;
}

const Accommodations: React.FC<IAccommodationsProps> = (props) => {
    const textColorInput = useColorModeValue('secondaryGray.900', 'white');
    const [searchValue, setSearchValue] = useState('');
    const searching = false;
    let filteredAccommodations: Accommodation[] = [];

    /* eslint-disable */
    useEffect(() => {
        accommodationStore.getCategoryWithAccommodations(accommodationStore.newRequest.categoryId);
        if (accommodationStore.newRequest?.accommodationId) { props.setIsValid(true); }
    }, []);

    if (accommodationStore.category) {
        const accommodationsCount = accommodationStore.category.accommodations.length;

        if (accommodationsCount > 0) {
            filteredAccommodations = accommodationStore.category.accommodations.filter((accommodation: any) =>
                accommodation.name.toLowerCase().includes(searchValue.toLowerCase())
            );
        }
    }

    const handleChange = (value: string) => {
        if (value) {
            accommodationStore.setNewRequestData({ accommodationId: value });
            props.setIsValid(true);
        }
    }

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'framework',
        value: accommodationStore.newRequest?.accommodationId,
        onChange: (value: string) => handleChange(value),
    });
    const group = getRootProps();

    const getAccommodations = (): React.ReactElement => {
        const accommodationsCount = filteredAccommodations.length;
        if (accommodationsCount > 0) {
            return (
                <LoadingOverlay isLoading={searching}>
                    <SimpleGrid overflowY={'auto'} pb={'5'} {...group} columns={accommodationsCount > 3 ? [1, 1, 2, 3] : accommodationsCount > 2 ? [1, 1, 2, 2] : [1, 1, 1, 1]} spacing={5}>
                        {filteredAccommodations.map((accommodation: any) => {
                            const { id: value, name } = accommodation;
                            const radio = getRadioProps({ value });
                            return (
                                <RadioCard key={value} {...radio}>
                                    {name}
                                </RadioCard>
                            );
                        })}
                    </SimpleGrid>
                </LoadingOverlay>
            );
        }
        return (
            <Alert status='warning'>
                <AlertIcon />
                No accommodations found.
            </Alert>
        );
    }

    return (
        <LoadingOverlay isLoading={accommodationStore.loading}>
            <Box>
                <Text px={{ base: '0', lg: '15%' }} fontSize={{ base: 'xl', lg: '2xl' }} align={'center'} fontWeight='bold'>
                    3. Select the accommodation that will support you
                </Text>

                <Flex direction={'column'} justifyContent={'center'} alignItems={'center'}>
                    <Text my={5} fontStyle={'italic'} fontSize={'lg'} color={'brand'} align={'center'}>
                        <InfoIcon mr={2}/> Each accommodation requires a separate ticket.
                    </Text>
                    <Box width={{ base: '100%', lg: '450px' }}>
                        <InputGroup>
                            <InputLeftElement
                                zIndex={0}
                                pointerEvents='none'
                                children={<SearchIcon color='gray.300' />}
                            />
                            <Input onChange={(e) => setSearchValue(e.target.value)} color={textColorInput} size={'lg'} focusBorderColor='#207077' colorScheme={'brand'} type='tel' placeholder='eg. Screen Reader, Adjustable desk...' />
                        </InputGroup>
                    </Box>
                    <Box mt={'20px'}>
                        {getAccommodations()}
                    </Box>
                </Flex>
            </Box>
        </LoadingOverlay >
    );
}

const RadioCard = (props: any) => {
    const { getInputProps, getCheckboxProps } = useRadio(props);
    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
        <Box as='label'>
            <input {...input} />
            <Box
                {...checkbox}
                textAlign='center'
                cursor='pointer'
                borderWidth='1px'
                borderRadius='md'
                boxShadow='md'
                _checked={{
                    bg: 'teal.600',
                    color: 'white',
                    borderColor: 'teal.600',
                }}
                _focus={{ boxShadow: 'outline' }}
                px={5}
                py={3}
            >
                {props.children}
            </Box>
        </Box>
    )
}

export default observer(Accommodations);
