import { AxiosRequestConfig } from 'axios';

export const axiosBaseOptions: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'X-Tenant': process.env.REACT_APP_TENANT as string,
    'X-Spa-Type': process.env.REACT_APP_SPA_TYPE as string,
    'Content-type': 'application/json',
    'Accept': 'application/json',
  },
}

export const requestOptionForFormData: AxiosRequestConfig = {
  headers: { 'Content-Type': 'multipart/form-data' },
};
