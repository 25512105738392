import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Portal, Box, useDisclosure } from '@chakra-ui/react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Navbar from 'components/navbar/NavbarAdmin';
import Sidebar from 'components/sidebar/Sidebar';
import { SidebarContext } from 'contexts/SidebarContext';
import routes from 'routes/routes';
import { echoService } from '../../services/EchoService';
import userStore from 'store/dataStore/userStore';
import notificationStore from 'store/dataStore/notificationStore';
import LoadingOverlay from '../../components/loader/LoadingOverlay';

interface props {
    [x: string]: any;
}

const EmployeeDashboardLayout: React.FC<props> = (props) => {
    const { ...rest } = props;
    const [toggleSidebar, setToggleSidebar] = useState(false);

    useEffect(() => {
        const EchoInstance = echoService.createConnection();

        if (!userStore.user) {
            userStore.setLoading(true, "Loading W-PAS App");
            userStore.getUser().then(() => {
                notificationStore.getNotifications().then(() => {
                    EchoInstance.private('App.Models.User.' + userStore.user.id).notification(
                        (notification: any) => {
                            notificationStore.addNotification(notification);
                            notificationStore.setUnreadCount(notificationStore.unreadCount + 1);
                        }
                    );
                }).catch((error) => {
                    userStore.setErrors({status: error.status, message: error.data.message});
                });
            }).catch((error) => {
                userStore.setErrors({status: error.status, message: error.data.message});
            }).finally(() => {
                userStore.setLoading(false);
            });
        }

        return () => {
            EchoInstance.disconnect();
        };
    }, []);

    const getPageTitle = (routes: RoutesType[]): string => {
        let activeRoute = '';
        for (let i = 0; i < routes.length; i++) {
            const { layout, path, name } = routes[i];
            let route = layout + path;
            if (route.includes(':id')) {
                route = route.replace(':id', '');
            }
            if (window.location.pathname.startsWith(route)) {
                return name;
            }
        }

        return activeRoute;
    };

    const getActiveNavbarItem = (routes: RoutesType[]): boolean => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (
                window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
            ) {
                return routes[i].secondary;
            }
        }
        return activeNavbar;
    };

    const getActiveNavbarText = (routes: RoutesType[]): string | boolean => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (
                window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
            ) {
                return routes[i].name;
            }
        }
        return activeNavbar;
    };

    const getRoutes = (routes: RoutesType[]): any => {
        return routes.map((route: RoutesType, key: any) => {
            if (route.layout === '/employee') {
                return (
                    <Route
                        exact
                        path={ route.layout + route.path }
                        component={ route.component }
                        key={ key }
                    />
                );
            } else {
                return null;
            }
        });
    };

    document.documentElement.dir = 'ltr';
    const { onOpen } = useDisclosure();

    return (
        <Box>
            <LoadingOverlay isLoading={userStore.loading} isGlobal={true} message={userStore.loadingMessage}>
                <SidebarContext.Provider value={ { toggleSidebar, setToggleSidebar } }>
                    <Sidebar routes={ routes } display="none" { ...rest } />
                    <Box
                        float="right"
                        minHeight="100vh"
                        height="100%"
                        overflow="auto"
                        position="relative"
                        maxHeight="100%"
                        w={ { base: '100%', xl: 'calc( 100% - 290px )' } }
                        maxWidth={ { base: '100%', xl: 'calc( 100% - 290px )' } }
                        transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
                        transitionDuration=".2s, .2s, .35s"
                        transitionProperty="top, bottom, width"
                        transitionTimingFunction="linear, linear, ease"
                    >
                        <Portal>
                            <Box className="navMain">
                                <Navbar
                                    onOpen={ onOpen }
                                    logoText={ 'Magnify Access Logo' }
                                    brandText={ getPageTitle(routes) }
                                    secondary={ getActiveNavbarItem(routes) }
                                    message={ getActiveNavbarText(routes) }
                                    fixed={ false }
                                    { ...rest }
                                />
                            </Box>
                        </Portal>
                        <Box
                            mx="auto"
                            p={ { base: '20px', md: '30px' } }
                            pe="20px"
                            minH="100vh"
                            pt="50px"
                        >
                            <Switch>
                                { getRoutes(routes) }
                                <Redirect from="/"
                                    to="/employee/dashboard"/>
                            </Switch>
                        </Box>
                    </Box>
                </SidebarContext.Provider>
            </LoadingOverlay>
        </Box>
    );
};

export default observer(EmployeeDashboardLayout);
