import { Icon } from '@chakra-ui/react';
import { StatusEnum } from 'store/models/status';
import { MdOutlineArchive, MdCheckCircle } from 'react-icons/md';
import { RiDraftLine } from 'react-icons/ri';
import { TbClock } from 'react-icons/tb';
import { TiCancel } from 'react-icons/ti';
import { IoArrowUndoCircleOutline } from 'react-icons/io5';

type IAccommodationStatus = {
  [key in StatusEnum]: {
    title: string;
    icon: JSX.Element;
  };
};

export const AccommodationRequestStatus: IAccommodationStatus = {
  [StatusEnum.DRAFT]: {
    title: 'Draft',
    icon: <Icon width='24px' height='24px' color='secondaryGray.500' as={RiDraftLine} />,
  },
  [StatusEnum.SUBMITTED]: {
    title: 'Submitted',
    icon: <Icon width='24px' height='24px' color='brand.500' as={MdOutlineArchive} />,
  },
  [StatusEnum.INPROGRESS]: {
    title: 'In Progress',
    icon: <Icon width='24px' height='24px' color='yellow.500' as={TbClock} />,
  },
  [StatusEnum.WITHDRAWN]: {
    title: 'Withdrawn',
    icon: <Icon width='24px' height='24px' color='orange.500' as={IoArrowUndoCircleOutline} />,
  },
  [StatusEnum.IMPLEMENTED]: {
    title: 'Implemented',
    icon: <Icon width='24px' height='24px' color='green.500' as={MdCheckCircle} />,
  },
  [StatusEnum.DENIED]: {
    title: 'Denied',
    icon: <Icon width='24px' height='24px' color='red.500' as={TiCancel} />,
  },
};
