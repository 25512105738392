import React, { useState } from 'react';
import { Alert, AlertIcon, Box, Button, Flex, SimpleGrid, Text, useBreakpointValue, useRadioGroup } from '@chakra-ui/react';
import LoadingOverlay from 'components/loader/LoadingOverlay';
import RadioCard from 'components/RadioCard/RadioCard';
import SubmitForm from './SubmitForm';

interface IRecommendedAccommodationsProps {
    [x: string]: any;
}

const RecommendedAccommodations: React.FC<IRecommendedAccommodationsProps> = (props) => {
    const textColorSecondary = 'gray.600';
    const { recommendedAccommodations } = props;
    const buttonSize = useBreakpointValue({ base: "md", lg: "lg" }, {
        ssr: false,
    } as any);

    const [selectedAccommodationId, setSelectedAccommodationId] = useState('');

    const handleChange = (value: string) => {
        if (value) {
            setSelectedAccommodationId(value);
        }
    }

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'framework',
        value: selectedAccommodationId,
        onChange: (value: string) => handleChange(value),
    });
    const group = getRootProps();

    return (
        <LoadingOverlay isLoading={false}>
            <Text px={{ base: '0', lg: '15%' }} fontSize={{ base: 'xl', lg: '2xl' }} align={'center'} fontWeight='bold'>
                Thank you for finishing the quiz!
            </Text>

            {!recommendedAccommodations.length ?
                <Flex direction={'column'} alignItems={'center'} px={{ base: '0', lg: '15%' }} mt={10}>
                    <Text
                        px={{ base: '0', lg: '15%' }}
                        color={textColorSecondary}
                        fontSize={['md', 'lg', 'xl', 'xl']}
                        my={{ base: 'auto', '2xl': '10px' }}
                        mx='auto'
                        textAlign='center'
                    >
                        We could not find any accommodations that would assist you based on your answers.
                    </Text>
                    <Button
                        my={10}
                        onClick={() => props.handleRestart()}
                        size={buttonSize}
                        colorScheme='brand'
                        variant={'link'}
                    >
                        {`Take the quiz again`}
                    </Button>

                </Flex> :
                <>
                    <Text
                        px={{ base: '0', lg: '15%' }}
                        color={textColorSecondary}
                        fontSize={['md', 'lg', 'xl', 'xl']}
                        my={{ base: 'auto', '2xl': '10px' }}
                        mx='auto'
                        textAlign='center'
                    >
                        We think that following accommodations would help you in your workplace. Please select one of the following to submit a request.
                    </Text>
                    <Flex mt={'20px'} direction={'column'} justifyContent={'center'} alignItems={'center'}>
                        <Box mt={'20px'}>
                            <SimpleGrid overflowY={'auto'} pb={'5'} {...group} columns={recommendedAccommodations.length > 3 ? [1, 1, 2, 3] : recommendedAccommodations.length > 2 ? [1, 1, 2, 2] : [1, 1, 1, 1]} spacing={5}>
                                {recommendedAccommodations.map((accommodation: any) => {
                                    const { id: value, name } = accommodation;
                                    const radio = getRadioProps({ value });
                                    return (
                                        <RadioCard key={value} {...radio}>
                                            {name}
                                        </RadioCard>
                                    );
                                })}
                            </SimpleGrid>
                        </Box>
                        <Box px={{ base: '0', lg: '15%' }} mt={10}>
                            <Alert mt={'5'} textAlign='center' colorScheme={'brand'} status='info'>
                                <AlertIcon />
                                Each accommodation requires a separate ticket.
                            </Alert>
                        </Box>
                    </Flex>
                </>
            }
            <Box px={{ base: '0', lg: '10%' }}>
                {selectedAccommodationId ? <SubmitForm accommodationId={selectedAccommodationId} /> : ''}
            </Box>
        </LoadingOverlay>
    );
}

export default RecommendedAccommodations;
