import { Box, Flex, Grid } from '@chakra-ui/react';
import AccommodationRequestCard from '../components/accommodations/AccommodationRequestCard';
import content from 'utils/Language/content.json';

export default function EmployeeDashboard() {
	return (
		<Box pt={{ base: '180px', md: '150px', xl: '100px' }}>
			<Grid>
				<Flex flexDirection='column'>
					<AccommodationRequestCard content={content.accommodations} />
				</Flex>
			</Grid>
		</Box>
	);
}
