import { Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { FileRejection } from 'react-dropzone';
import { MdUpload } from 'react-icons/md';
import Dropzone from './Dropzone';
import { observer } from 'mobx-react';
interface IUploadProps {
  handleFileUpload: (
    acceptedFiles: File[],
    rejectedFiles: FileRejection[]
  ) => void;
  imagesOnly?: boolean;
  [x: string]: any;
}

const Upload: React.FC<IUploadProps> = (props) => {
  const { handleFileUpload, imagesOnly, ...rest } = props;
  const brandColor = useColorModeValue('brand.500', 'brand.200');
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Flex {...rest} justifyContent={'center'} direction={{ base: 'column' }}>
      <Dropzone
        handleFileUpload={handleFileUpload}
        {...rest}
        content={
          <Box>
            <Icon as={MdUpload} w="80px" h="80px" color={brandColor} />
            <Flex justify="center" mx="auto" mb="12px">
              <Text fontSize="xl" fontWeight="700" color={brandColor}>
                {imagesOnly ? 'Upload Image' : 'Upload Files'}
              </Text>
            </Flex>
            <Text fontSize="sm" fontWeight="500" color={textColor}>
              {imagesOnly
                ? 'Photos only (5MB Max Size)'
                : 'Photos and documents only (5MB Max Size)'}
            </Text>
          </Box>
        }
      />
    </Flex>
  );
};

export default observer(Upload);
